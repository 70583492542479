import React, { FC } from "react";

interface ITooltipRccProps {
    imageUrl: string;
    title: string
}

const TooltipRcc: FC<ITooltipRccProps> = ({ imageUrl, title }) => {
    return (
        <div className="tooltip-rcc" style={{ textAlign: "center" }}>
            <img
                src={imageUrl}
                alt="Icon"
                className="toottip-icon"
            />
            {title}
        </div>
    );
};

export default TooltipRcc;