import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Modal from "../../../UI/Modal";
import { showManageCardModal } from "../../../../store/auth/action";
import { URLS } from "../../../../constants/url";
import "./manageCard.scss";
import List from "./List";
import AddCard from "./Add";
import { CardDetail } from "../../../../models/common";
import {COUNTRY} from "../../../../constants/app";

interface IManageCardsProps {}

const ManageCards: FC<IManageCardsProps> = () => {
  const { openManageCardModal, countryId } = useSelector((state: RootState) => ({
    openManageCardModal: state.authReducer.openManageCardModal,
    countryId: state.applicationReducer.countryId,
  }));

  const dispatch = useDispatch();
  const [selectedCard, setSelectedCard] = useState<CardDetail | null>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [tabType, setTabType] = useState<string>("manage_cards");


  const onCloseModal = () => {
    setTabType("manage_cards");
    dispatch(showManageCardModal(false));
  };

  const onChange = (type: string) => {
    setTabType(type);
    setIsEdit(false);
    setSelectedCard(null)
  };

  const onReset = ()=>{
    setSelectedCard(null);
    setIsEdit(false)
    setTabType('manage_cards')
  }

  const cardContent = (<div className="manage_card_icons">
    <div className="buttton-row">
      <button
          className={`tab-buttons credit_card ${
              tabType === "manage_cards" ? "active" : ""
          }`}
          onClick={() => onChange("manage_cards")}
      >
        Manage Card
      </button>
      {!isEdit ? <button
          className={`tab-buttons credit_card mx-3 ${
              tabType === "add" ? "active" : ""
          }`}
          onClick={() => onChange("add")}
      >
        Add New Card
      </button> : null}
    </div>
    {tabType === "manage_cards" ? (
        <List
            setSelectedCard={setSelectedCard}
            setIsEdit={setIsEdit}
            setTabType={setTabType}
        />
    ) : (
        <AddCard
            data={selectedCard}
            isEdit={isEdit}
            setTabType={setTabType}
            onReset={onReset}
        />
    )}
  </div>);

  return (
    <>
      {countryId === COUNTRY.AFC ? (
        <div className="payment-wrapper manage-cards-popup-content">
          <div className="payment-header">
            <div className="title title_append">Payment Options</div>
            <div className="underline"></div>
          </div>
          <div className="gift-card-body">
            {cardContent}
          </div>
        </div>
      ) : (
        <Modal visible={openManageCardModal} className="manage-cards-popup" showCloseBtn={false}>
          <div className="modal fade show" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered modal-lg ">
              <div className="modal-content">
                <button
                  type="button"
                  className="close-modal"
                  onClick={onCloseModal}
                >
                  <img
                    className="close_icon"
                    src={URLS.CLOSE_ICON}
                    alt="close_icon"
                    title="close_icon"
                  />
                </button>
                <div className="modal-header">
                  <div className="title title_append">Payment Options</div>
                </div>
                <div className="modal-body">
                  {cardContent}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ManageCards;
