import React, { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setContentModal,
  toggleContentModal,
} from "../../../../store/application/action";
import { ROUTES } from "../../../../constants/path";

interface ICinemaExtrasProps {}

const CinemaExtras: FC<ICinemaExtrasProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setContentModal({ type: ROUTES.CINEMA_EXTRAS }));
    dispatch(toggleContentModal(true));
  }, []);

  return null;
};
export default CinemaExtras;
