import { IAuthMenu, IMOVIE_TYPES, ISide_Menu } from "../models/common";
import { ROUTES } from "./path";
import { URLS } from "./url";

export enum DOMAINS {
    AUS = 'readingcinemas.com.au',
    NZ = 'readingcinemas.co.nz',
    ANG = 'angelikafilmcentre.com.au',
    STA = 'statecinema.com.au',
    US = 'readingcinemas.com',
    AFC = 'angelikafilmcenter.com',
    CON = 'consolidatedtheatres.com',
}

export enum COUNTRY {
    AUS = '1',
    NZ = '2',
    ANG = '3',
    STA = '4',
    US = '5',
    AFC = '6',
    CON = '7',
}

export enum BRANDS {
    AU = 'AU',
    US = 'US'
}

export const BRAND_ID = {
    [COUNTRY.AUS]: BRANDS.AU,
    [COUNTRY.NZ]: BRANDS.AU,
    [COUNTRY.ANG]: BRANDS.AU,
    [COUNTRY.STA]: BRANDS.AU,
    [COUNTRY.US]: BRANDS.US,
    [COUNTRY.CON]: BRANDS.US,
    [COUNTRY.AFC]: BRANDS.US,
}

export const CURRENCY = {
    [COUNTRY.AUS]: 'AUD',
    [COUNTRY.NZ]: 'NZD',
    [COUNTRY.ANG]: 'AUD',
    [COUNTRY.STA]: 'AUD',
    [COUNTRY.US]: 'USD',
    [COUNTRY.CON]: 'USD',
    [COUNTRY.AFC]: 'USD',
}

export const COUNTRY_CODE = {
    [COUNTRY.AUS]: 'AU',
    [COUNTRY.NZ]: 'NZ',
    [COUNTRY.ANG]: 'AU',
    [COUNTRY.STA]: 'AU',
    [COUNTRY.US]: 'US',
    [COUNTRY.CON]: 'US',
    [COUNTRY.AFC]: 'US',
}
export const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCH_SITE_KEY;

export const TIMER_DURATION = 600;
export const TIMER_DURATION_FB = 600;

export const GOOGLE_SITE_VERIFICATION_KEY ={
    [COUNTRY.AUS]: '',
    [COUNTRY.NZ]: '',
    [COUNTRY.ANG]: '',
    [COUNTRY.STA]: '',
    [COUNTRY.US]: 'DMm4raa6Uax9cdMvAfn_YVenA1AuxfigezCja_s0lb0',
    [COUNTRY.CON]: 'tb1f1xhLfkxuzXbBdLtpi_WJbzQeGqH9_diHBNktrdM',
    [COUNTRY.AFC]: 'LdqxbzWVjYST0YVmuKdkXA8yPQye4JobVoX6sRtWajA',
}

export const SITE_URL = {
    [COUNTRY.AUS]: 'https://readingcinemas.com.au',
    [COUNTRY.NZ]: 'https://readingcinemas.co.nz',
    [COUNTRY.ANG]: 'https://angelikacinemas.com.au',
    [COUNTRY.STA]: 'https://statecinema.com.au',
    [COUNTRY.US]: 'https://readingcinemas.com',
    [COUNTRY.CON]: 'https://consolidatedtheatres.com',
    [COUNTRY.AFC]: 'https://angelikafilmcenter.com',
}

export enum PAYMENT_PROVIDERS {
    FATZEBRA = 'fatzebra',
    STRIPE = 'stripe',
    PAYEEZY = 'payeezy'
}

export const GIFT_CARD_CINEMA_ID = {
    [COUNTRY.NZ]: 1,
    [COUNTRY.AUS]: '0000000001',
    [COUNTRY.STA]: '0000000001',
    [COUNTRY.ANG]: '0000000001',
    [COUNTRY.US]:  '0000000024',
    [COUNTRY.AFC]: '0000000024',
    [COUNTRY.CON]: '0000000024',
}

export const GIFT_CARD_DELIVERY_FEE = {
    NZ: 9.57,
    AU: 7.7,
    ANG: 7.7,
    STA: 7.7
}

export const SIDE_MENU: ISide_Menu[] = [
    {
        header: {
            [COUNTRY.AUS]: "CINEMAS",
            [COUNTRY.NZ]: "CINEMAS",
            [COUNTRY.ANG]: "",
            [COUNTRY.STA]: "INFO"
        },
        alias: "cinemas",
        menus: [
            {
                label: "Location Map",
                alias: "locationMap",
                type: "page",
                path: `/${ROUTES.CINEMAS}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "History",
                alias: "history",
                type: "page",
                path: `/${ROUTES.HISTORY}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "Café & Bar",
                alias: "cafeAndBar",
                type: "page",
                path: `/${ROUTES.CAFEBAR}`,
                enable: [COUNTRY.STA]
            },
            {
                label: "Advertising",
                alias: "advertising",
                type: "page",
                path: `/${ROUTES.ADVERTISING}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "Luxury Cinemas",
                alias: "luxuryCinemas",
                type: "page",
                path: "/",
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "VIP Cinema Experience",
                alias: "vipCinemaExperience",
                type: "page",
                path: `/${ROUTES.VIP_EXPERIENCE}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "Big Screen Gaming",
                alias: "bigScreenGaming",
                type: "page",
                path: `/${ROUTES.VIP_EXPERIENCE}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "Group Bookings",
                alias: "groupBooking",
                type: "modal",
                path: ROUTES.GROUP_SALE,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA]
            },
            {
                label: "Cinema Hire",
                alias: "cinemaHire",
                type: "modal",
                path: ROUTES.CINEMA_HIRE,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "History",
                alias: "history",
                type: "page",
                path: `/${ROUTES.HISTORY}`,
                enable: [COUNTRY.STA]
            },
            {
                label: "Advertising",
                alias: "advertising ",
                type: "page",
                path: `/${ROUTES.ADVERTISING}`,
                enable: [COUNTRY.STA]
            },
            {
                label: "Join Our Mailing List",
                alias: "joinOurMailListing ",
                type: "modal",
                path: ROUTES.MAILINGLIST,
                enable: [COUNTRY.STA, COUNTRY.AUS, COUNTRY.NZ]
            },
        ]
    },
    {
        header: {
            [COUNTRY.AUS]: "EXTRAS",
            [COUNTRY.NZ]: "EXTRAS",
            [COUNTRY.ANG]: "",
            [COUNTRY.STA]: "EXTRAS"
        },
        alias: "extras",
        menus: [
            {
                label: "About",
                alias: "about",
                type: "page",
                path: `/${ROUTES.ABOUT}`,
                enable: [COUNTRY.ANG]
            },
            {
                label: "Cinema Hire",
                alias: "cinemaHire",
                type: "page",
                path: `/${ROUTES.CINEMA_HIRE}`,
                enable: [COUNTRY.ANG]
            },
            {
                label: "Competitions",
                alias: "competitions",
                type: "page",
                path: `/${ROUTES.COMPETITIONS}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.ANG]
            },
            {
                label: "Kids Corner",
                alias: "kidsCorner",
                type: "page",
                path: `/${ROUTES.MOVIES}/${ROUTES.KIDS_NOW_SHOWING}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "Café & Bar",
                alias: "cafeAndBar",
                type: "page",
                path: `/${ROUTES.CAFEBAR}`,
                enable: [COUNTRY.AUS, COUNTRY.STA]
            },
            {
                label: "Membership",
                alias: "membership",
                type: "page",
                path: `/${ROUTES.MEMBERSHIP}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "Offers & Events",
                alias: "offersandEvents",
                type: "page",
                path: `/${ROUTES.OFFERS_EVENTS}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "Events",
                alias: "events",
                type: "page",
                path: `/${ROUTES.OFFERS_EVENTS}`,
                enable: [COUNTRY.STA]
            },
            {
                label: "Gift Shop",
                alias: "giftShop",
                type: "page",
                path: `/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.ANG]
            },
            {
                label: "Highline Terrace",
                alias: "highlineTrace",
                type: "page",
                path: `/${ROUTES.HIGHLINE_TERRACE}`,
                enable: [COUNTRY.ANG]
            },
            {
                label: "Reel Club",
                alias: "reelClub",
                type: "page",
                path: `/${ROUTES.REELCLUB}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "Membership",
                alias: "membership",
                type: "page",
                path: `/${ROUTES.MEMBERSHIP}`,
                enable: [COUNTRY.STA]
            },
            {
                label: "Angelika Rewards",
                alias: "rewards",
                type: "page",
                path: `/${ROUTES.REWARDS}`,
                enable: [COUNTRY.ANG]
            },
            {
                label: "Corporate Cinemoney",
                alias: "corporateCinemony",
                type: "modal",
                path: ROUTES.CINEMONY,
                enable: [COUNTRY.AUS, COUNTRY.NZ]
            },
            {
                label: "State Bookstore",
                alias: "stateBookstore",
                type: "link",
                path: URLS.BOOK_STORE_STATE,
                enable: [COUNTRY.STA, COUNTRY.AUS]
            },
            {
                label: "Café & Bar",
                alias: "cafeAndBar",
                type: "page",
                path: `/${ROUTES.CAFEBAR}`,
                enable: [COUNTRY.STA]
            },
            {
                label: "Offers",
                alias: "events",
                type: "page",
                path: `/${ROUTES.OFFERS_EVENTS}`,
                enable: [COUNTRY.ANG]
            },
            {
                label: "SoHo Lounge",
                alias: "sohoLounge",
                type: "page",
                path: `/${ROUTES.SOHOLOUNGE}`,
                enable: [COUNTRY.ANG, COUNTRY.AUS]
            },
            {
                label: "Angelika Anywhere",
                alias: "angelikaAnywhere",
                type: "link",
                path: URLS.ANGELIKA_ANYWHERE_URL,
                enable: [COUNTRY.STA, COUNTRY.AUS]
            },
        ]
    },
    {
        header: {},
        alias: "",
        menus: [
            {
                label: "Contact Us",
                alias: "contactUs",
                type: "modal",
                path: ROUTES.CONTACT_US,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.ANG, COUNTRY.STA]
            },
            {
                label: "FAQs",
                alias: "faq",
                type: "page",
                path: `/${ROUTES.FAQ}`,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.ANG, COUNTRY.STA]
            },
            {
                label: "Employment",
                alias: "employment",
                type: "modal",
                path: ROUTES.CAREER,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.ANG, COUNTRY.STA]
            },
            {
                label: "Ratings Index",
                alias: "ratingsIndex",
                type: "modal",
                path: ROUTES.RATING,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.ANG, COUNTRY.STA]
            },
            {
                label: "Terms",
                alias: "terms",
                type: "modal",
                path: ROUTES.TERMS,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA]
            },
            {
                label: " Site Disclaimer",
                alias: "siteDisclaimer",
                type: "modal",
                path: ROUTES.SITE_DISCLAIMER,
                enable: [COUNTRY.ANG]
            },
            {
                label: "Privacy Policy",
                alias: "privacyPolicy",
                type: "modal",
                path: ROUTES.PRIVACY,
                enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.ANG, COUNTRY.STA]
            },
        ]
    },
]

export const AUTH_MENU: IAuthMenu = {
    loyaltyDetails: {
        enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA, COUNTRY.ANG]
    },
    userDetails: {
        enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA, COUNTRY.ANG, COUNTRY.US, COUNTRY.CON, COUNTRY.AFC]
    },
    loyalty: {
        enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA, COUNTRY.ANG, COUNTRY.US, COUNTRY.CON, COUNTRY.AFC]
    },
    upcomingShow: {
        enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA, COUNTRY.ANG, COUNTRY.US, COUNTRY.CON, COUNTRY.AFC]
    },
    purchaseHistorySection: {
        enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA, COUNTRY.ANG, COUNTRY.US, COUNTRY.CON]
    },
    rewardsHistorySection: {
        enable: [COUNTRY.AFC]
    },
    accountDetails: {
        enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA, COUNTRY.ANG, COUNTRY.US, COUNTRY.CON, COUNTRY.AFC]
    },
    wallet: {
        enable: [COUNTRY.AUS, COUNTRY.NZ, COUNTRY.STA, COUNTRY.ANG, COUNTRY.US, COUNTRY.CON, COUNTRY.AFC]
    },
}

export const MOVIE_TYPES: IMOVIE_TYPES = {
    standard: {
        class: 'standard',
        title: 'Standard',
        active: false,
    },
    premium: {
        class: 'premium',
        title: 'Premium',
        active: false,
    },
    titanxc: {
        class: 'titanxc',
        title: 'Titan XC',
        active: false,
    },
    titanluxe: {
        class: 'titanluxe',
        title: 'Titan Luxe',
        active: false,
    },
    gold: {
        class: 'goldlounge',
        title: 'Gold Lounge',
        active: false,
    },
    soho: {
        class: 'soholounge',
        title: 'Soho',
        active: false,
    },
};

export const GENDER_LIST = [
    {
        value: 'Male',
        label: 'Male'
    },
    {
        value: 'Female',
        label: 'Female'
    },
    {
        value: 'Unknown',
        label: 'Other'
    }
]

export const GENDER_LIST_STA = [
    {
        value: 'Male',
        label: 'Man'
    },
    {
        value: 'Female',
        label: 'Woman'
    },
    {
        value: 'Non-Binary/Gender-Diverse',
        label: 'Non-Binary/Gender-Diverse'
    },
    {
        value: 'Unknown',
        label: 'Other'
    }
]

export const GENDER_LIST_US = [
    {
        value: 'I decline to self-identify',
        label: 'I decline to self-identify'
    },
    {
        value: 'Male',
        label: 'As a male'
    },
    {
        value: 'Female',
        label: 'As a female'
    },
    {
        value: 'As non-binary or two spirit',
        label: 'As non-binary or two spirit'
    }
]


export const GENRE_LIST = [
    { value: 1, label: 'Action' },
    { value: 2, label: 'Adventure' },
    { value: 3, label: 'Comedy' },
    { value: 4, label: 'Documentary' },
    { value: 5, label: 'Family' },
    { value: 6, label: 'Horror' },
    { value: 7, label: 'Music' },
    { value: 8, label: 'Romance' },
    { value: 9, label: 'Sci-fi' },
    { value: 10, label: 'Thriller' }
]

export const MONTH_LIST = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
];

export const VACCINATION_LOCATIONS = [
    "auburn",
    "charlestow",
    "dubbo",
    "Maitland",
    "rhodes",
    "Rousehill",
    "Burwood",
    "chirnside",
    "dandenong",
    "epping",
    "Melton",
    "Sunbury",
    "waurnponds",
]

export const AES_KEY = 'reading';

export const SLICK_SETTING = {
    className: "filter-show-times",
    centerPadding: "10px",
    dots: false,
    lazyload: true,
    infinite: false,
    speed: 250,
    slidesToShow: 7,
    autoplay: false,
    cssEase: "linear",
    slidesToScroll: 1,
    slidesPerRow: 1,
    swipeToSlide: true,
    adaptiveHeight: true,
    arrows: true,
    centerMode: false,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 1
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        }
    ],
};

export const SLICK_SETTING_WHEN = {
    className: "filter-show-times variable-width",
    centerPadding: "0px",
    dots: false,
    lazyload: false,
    infinite: false,
    variableWidth: true,
    speed: 250,
    // slidesToShow: 6,
    autoplay: false,
    // cssEase: "linear",
    slidesToScroll: 1,
    slidesPerRow: 1,
    swipeToSlide: true,
    // adaptiveHeight: true,
    arrows: true,
    centerMode: false,
    responsive: [
        {
            breakpoint: 2000,
            settings: {
                // slidesToShow: 6,
                slidesToScroll: 1
            },
        },
        {
            breakpoint: 1500,
            settings: {
                // slidesToShow: 4,
                slidesToScroll: 1
            },
        },
        {
            breakpoint: 1400,
            settings: {
                // slidesToShow: 4,
                slidesToScroll: 1
            },
        },
        {
            breakpoint: 1024,
            settings: {
                // slidesToShow: 3,
                slidesToScroll: 1
            },
        },
        {
            breakpoint: 900,
            settings: {
                // slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                arrows: false,
                // slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
    ],
};

export const SHOW_REWARDS = ['manville']

export const CONTACT_US_SEND_MAIL: any = {
    [COUNTRY.AUS]: [
        {
            value: 'cinema',
            label: 'Cinema',
            onSelect: 'cinema'
        },
        {
            value: 'gamingHires',
            label: 'Gaming Hires',
            onSelect: 'gaminghires'
        },
        {
            value: 'readingReelClub',
            label: 'Reading Reel Club',
            onSelect: 'reelclub'
        },
        {
            value: 'eTixSupport',
            label: 'E-Tix Support',
            onSelect: 'etix'
        },
        {
            value: 'giftShop',
            label: 'Gift Shop',
            onSelect: 'giftshop'
        },
        {
            value: 'headOffice',
            label: 'Head Office',
            onSelect: 'headoffice'
        }
    ],
    [COUNTRY.STA]: [

        {
            value: 'cinema',
            label: 'Cinema Manager',
            onSelect: 'cinema'
        },
        {
            value: 'eTixSupport',
            label: 'E-Tix Support',
            onSelect: 'etix'
        },
        {
            value: 'giftShop',
            label: 'Gift Shop',
            onSelect: 'giftshop'
        },
        {
            value: 'headOffice',
            label: 'Head Office',
            onSelect: 'headoffice'
        }

    ],
    [COUNTRY.ANG]: [
        {
            value: 'cinema',
            label: 'Cinema Manager',
            onSelect: 'cinema'
        },
        {
            value: 'eTixSupport',
            label: 'E-Tix Support',
            onSelect: 'etix'
        },
        {
            value: 'giftShop',
            label: 'Gift Shop',
            onSelect: 'giftshop'
        },
        {
            value: 'headOffice',
            label: 'Head Office',
            onSelect: 'headoffice'
        }

    ],
    [COUNTRY.US]: [
        {
            value: 'cinema',
            label: 'Cinema',
            onSelect: 'cinema'
        },
        {
            value: 'gamingHires',
            label: 'Gaming Hires',
            onSelect: 'gaminghires'
        },
        {
            value: 'eTixSupport',
            label: 'E-Tix Support',
            onSelect: 'etix'
        },
        {
            value: 'giftShop',
            label: 'Gift Shop',
            onSelect: 'giftshop'
        },
        {
            value: 'headOffice',
            label: 'Head Office',
            onSelect: 'headoffice'
        },
        {
            value: 'refund',
            label: 'Refund',
            onSelect: 'refund'
        },
        {
            value: 'lostAndFound',
            label: 'Lost and Found',
            onSelect: 'lostAndFound'
        },
        {
            value: 'advertising',
            label: 'Advertising',
            onselect: 'advertising'
        }
    ]
}

export const CONTACT_US_ETIX_ERROR = [
    { value: "Movie Selection", label: "Movie Selection" },
    { value: "Ticket Type Selection", label: "Ticket Type Selection" },
    { value: "Seat Selection", label: "Seat Selection" },
    { value: "Login", label: "Login" },
    { value: "Payment", label: "Payment" },
    { value: "Confirmation", label: "Confirmation" },
    { value: "Print Ticket", label: "Print Ticket" },
]

export const TimeOfDayList = [
    { value: 'Morning', label: 'Morning' },
    { value: 'Afternoon', label: 'Afternoon' },
    { value: 'Evening', label: 'Evening' },
    { value: 'Late Evening', label: 'Late Evening' }
];

export const FLIM_TYPE = [
    { id: 1, label: 'Gold Lounge', flag: 'Gold', alias: 'gold' },
    { id: 2, label: 'Titan LUXE', flag: 'TitanLuxe', alias: 'titan-luxe' },
    { id: 3, label: 'Titan XC', flag: 'TitanXC', alias: 'titan-xc' },
    { id: 4, label: 'Premium', flag: 'Premium', alias: 'premium' },
    { id: 5, label: 'Standard', flag: 'Standard', alias: 'standard' },
    { id: 6, label: 'IMAX', flag: 'IMAX', alias: 'imax' }
]

export const LOCATION_MAPPER: any = {
    [COUNTRY.US]: {
        "19": { alias: "grossmont", displayName: "Grossmont", neighboringCity: ["0000000001"]},
        "20": { alias:"caloaks", displayName: "Cal Oaks", neighboringCity: []},
        "0000000001": { alias:"townsquare", displayName: "Town Square", neighboringCity: ["19"]},
        "0000000003": { alias:'manville', displayName: "Manville", neighboringCity: []},
        "17": { alias:"valleyplaza", displayName: "Valley Plaza", neighboringCity: []},
        "15": { alias:"rohnertpark", displayName: "Rohnert Park", neighboringCity: []}
    },
    [COUNTRY.CON]: {
        "22":  { alias: "kapolei", displayName: "Kapolei", neighboringCity: []},
        "0000000011":  { alias: "olino", displayName: "Olino", neighboringCity: []},
        "0000000002":  { alias: "mililani", displayName: "Mililani", neighboringCity: []},
        "13": { alias: "pearlridge", displayName: "Pearlridge", neighboringCity: []},
        "12": { alias: "ward", displayName: "Ward", neighboringCity: []},
        "24": { alias: "kahala", displayName: "Kahala", neighboringCity: []},
    },
    [COUNTRY.AFC]: {
        "0000000005": { alias: "nyc", displayName: "Angelika New York", neighboringCity: ['0000000004','21']},
        "0000000006": { alias: "mosaic", displayName: "Angelika at Mosaic in Fairfax", neighboringCity: []},
        "0000000007": { alias: "dc", displayName: "Angelika Pop-Up in DC", neighboringCity: []},
        "0000000008": { alias: "plano", displayName: "Angelika Plano", neighboringCity: []},
        "0000000009":{ alias: "dallas", displayName: "Angelika Dallas", neighboringCity: []},
        "0000000004": { alias: "villageeast", displayName: "Village East by Angelika", neighboringCity: ['21','0000000005']},
        "21": { alias: "cinemas123", displayName: "Cinema 123 by Angelika", neighboringCity: ['0000000004','0000000005']},
        "18": { alias: "sandiego", displayName: "Angelika Carmel Mountain", neighboringCity: []},
        "16": { alias: "tower", displayName: "The Tower Theatre by Angelika", neighboringCity: []},
    }
}

export const GOOGLE_MAP_LINK: any = {
    [COUNTRY.US]: {
        grossmont: "https://maps.google.com/?cid=4581011639230303143",
        caloaks: "https://maps.google.com/?cid=12235570164348311140",
        townsquare: "https://maps.google.com/?cid=2729864612056309423",
        manville: "https://maps.google.com/?cid=6225235624906920015",
        valleyplaza: "https://maps.google.com/?cid=10772636220349431365",
        rohnertpark: "https://maps.google.com/?cid=2729864612056309423"
    },
    [COUNTRY.CON]: {
        kapolei: "https://maps.google.com/?cid=5365927710968440150",
        olino: "https://maps.google.com/?cid=12767258039640501217",
        mililani: "https://maps.google.com/?cid=15290856721115718898",
        pearlridge: "https://maps.google.com/?cid=9003895584528113714",
        ward: "https://maps.google.com/?cid=8634027310783311764",
        kahala: "https://maps.google.com/?cid=16067097624662511193"
    },
    [COUNTRY.AFC]: {
        nyc: "https://maps.google.com/?cid=7794567259815053441",
        villageeast: "https://maps.google.com/?cid=15384945027930877356",
        cinemas123: "https://maps.google.com/?cid=1197862463925680092",
        plano: "https://maps.google.com/?cid=12392871587256300099",
        tower: "https://maps.google.com/?cid=12957301406002349803",
        mosaic: "https://maps.google.com/?cid=4208248618983430578",
        dc: "https://maps.google.com/?cid=15140048077221725540",
        dallas: "https://maps.google.com/?cid=8398487239377440526",
        sandiego: "https://maps.google.com/?cid=1083400324458223896"
    }
};

export const PREMIUM_SERCHRG_ATTR = {
    [BRANDS.AU]:['Soho'],
    [BRANDS.US]:['TITAN LUXE','TITAN LUXE with Dolby Atmos','TITAN LUXE 3D', 'IMAX','IMAX 2D','IMAX 3D','TITAN XC 3D','TITAN XC with Dolby Atmos','TITAN XC','Jaffe Art Theatre','70mm','35mm']
}

export const ALLOWED_MEMBER_VALIDATE = ["0000000003","0000000002","13","22","0000000011","12","24", "0000000005","0000000006","0000000007","0000000008","0000000009","0000000004","21","18","16","0000000003" ];

export const WALLET_PASS_KEY = "Uv=57%K9WLqZ"

export const ATTRIBUTE_TOOLTIP = {
    JAFFE: {
        image: "star-location.svg", id: "seat-section-tooltip", name: "jaffe", content: `<div style="margin-top: 5px;">An official Landmark by the New York Landmarks Preservation Commission, the Village East Cinema’s historic auditorium opened in 1926 as <br><b>the Jaffe Art Theatre</b>, and has been a celebrated arts space in the heart of the East Village.</div>`
    },
    CRY_BABY: {
        image: "crybaby.svg", id: "seat-section-tooltip", name: "Crybaby Matinee", content: `<div style="margin-top: 5px;">Movies for parents and their babies! We’ve got the <b>lights dimmed and volume down.</b> </br> Relax and enjoy a movie without worrying about unexpected tantrums or feedings in the dark.</div>`
    },
    DEFUALT: { image: "", id: "", name: "", content: "" }
}  

export const TERMS_CONDITIONS_ERROR = "Please agree Terms and Conditions"