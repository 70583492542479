import { FC, useEffect, useState } from "react";
import PopupModal from "../../../UI/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { memberValidationSuccess, memberValidationWatcher, setUserDetails, showUnsubscribeMembershipModal } from "../../../../store/auth/action";
import './styles.scss';
import { unsubscripeApi } from "../../../../services/auth";
import { toast } from "react-toastify";
import { IMAGE_URL_CONFIG } from "../../../../constants/url";
import Button from "../../../UI/Button";
import { COUNTRY } from "../../../../constants/app";

interface IUnsubscribeRewards { }

const UnsubscribeRewards: FC<IUnsubscribeRewards> = () => {
  const { openUnsubscribeMemberModal, userDetails, countryId, memberDetail } = useSelector((state: RootState) => ({
    openUnsubscribeMemberModal: state.authReducer.openUnsubscribeMemberModal,
    userDetails: state.authReducer.userDetails,
    countryId: state.applicationReducer.countryId,
    memberDetail: state.authReducer.memberDetail
  }));

  const dispatch = useDispatch();
  const [unnsubscribe, setUnsubscribe] = useState(false)

  const saveChange = async () => {
    if (unnsubscribe === false) {
      dispatch(showUnsubscribeMembershipModal(false));
      return
    }
    const payload = {
      email: userDetails?.email,
      Id: userDetails?.id,
      countryId: countryId,
      memberId: memberDetail?.LoyaltyMember?.MemberId
    }
    const { data: { statusCode } } = await unsubscripeApi(payload);
    if (statusCode === 200) {
      setUnsubscribe(false);
      dispatch(showUnsubscribeMembershipModal(false));
      dispatch(memberValidationSuccess(null));
      dispatch(
        setUserDetails({
          ...userDetails,
          cardNumber: '',
          vista_memberid: '',
        })
      );
      toast.success(<div className='membership-success'>
        <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'tick-black.svg'} />
        <span>
          {`You have successfully unsubscribed from ${countryId === COUNTRY.CON?'Consolidated Theatres':countryId === COUNTRY.AFC?'Angelika':'Reading Cinemas'} Rewards & Membership. `}
          {`Sorry to see you go, but you can always rejoin ${countryId === COUNTRY.CON?'Consolidated Theatres':countryId === COUNTRY.AFC?'Angelika':'Reading Cinemas'} Membership under the User Profile. `}
          Thank you.</span>
      </div>, { className: 'membership_toast', autoClose: 9000 });
    }
  }

  const onChangeOption = (data: boolean) => {
    setUnsubscribe(data)
  }

  return (
    <PopupModal
      visible={openUnsubscribeMemberModal}
      className="unsubscribe-popup"
      showCloseBtn={false}
    >
      <div
        className="modal fade show unsubscribe-popup"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg ">
          <div className="modal-content">
            <button
              className="close"
              type="button"
              onClick={() => dispatch(showUnsubscribeMembershipModal(false))}
            >
              <span className="close_icon"></span>
            </button>
            <div className="modal-header ng-star-inserted">
              <div className="modal-title">
                <div className="mobile_header">
                  {` ${countryId === COUNTRY.CON?'No longer need Consolidated Theatres Membership':
                    countryId === COUNTRY.AFC?'No longer need Angelika Rewards':'No longer need Reading Cinemas Membership'}?`}
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div>
                {`Are you sure you want to unsubscribe from ${countryId === COUNTRY.CON?'Consolidated Theatres':countryId === COUNTRY.AFC?'Angelika':'Reading Cinemas'} Membership?`}
              </div>
              <div className="checkbox-group">
                <div className="custom-control">
                  <input type="radio" value="no" id="no_unsubscribe" name="unsubscribe_checkbox" checked={unnsubscribe === false} onClick={() => onChangeOption(false)} />
                  <label htmlFor="no_unsubscribe">No, I want to keep my Membership</label>
                </div>
                <div className="custom-control">
                  <input type="radio" value="yes" id="yes_unsubscribe" name="unsubscribe_checkbox" checked={unnsubscribe === true} onClick={() => onChangeOption(true)} />
                  <label htmlFor="yes_unsubscribe">Yes, unsubscribe me</label>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button classNames="m-r-10" onClick={() => dispatch(showUnsubscribeMembershipModal(false))} text="Cancel" type="outline" />
                <Button onClick={saveChange} text="Save Changes" type="fill" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PopupModal>
  );
};

export default UnsubscribeRewards;
