import React from "react";

import './button.scss'

interface ButtonProps {
    type: 'outline' | 'fill',
    icon?: string,
    iconAlt?: string,
    iconWidth?: number,
    iconHeight?: number,
    iconPosition?: 'pre' | 'post',
    text?: string,
    onClick?: () => void,
    classNames?: string,
    disabled? : boolean
}
const Button: React.FC<ButtonProps> = ({text, icon, iconPosition, type, onClick, classNames,iconAlt, iconWidth, iconHeight, disabled}) => {
    return (
        <button className={`custom_button ${type ?? ''} ${classNames ?? ''} ${disabled ? 'disabled' : ''}`} onClick={onClick} disabled={disabled} >
            <div className="custom_button_container">
                {
                    icon && iconPosition === 'pre' ?
                    <img width={iconWidth} height={iconHeight} className={`icon ${iconPosition}`} src={icon} alt= {iconAlt ?? ''} />
                    : null
                }
                <p className="text">{text}</p>
                {
                    icon && iconPosition === 'post' ?
                    <img width={iconWidth} height={iconHeight} className={`icon ${iconPosition}`} src={icon} alt= {iconAlt ?? ''} />
                    : null
                }

            </div>

        </button>
    )
}

export default Button;