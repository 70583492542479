export const ROUTES = {
    //AU
    MOVIES: 'movies',
    NOW_SHOWING: 'now-showing',
    COMING_SOON: 'coming-soon',
    SPECIAL_EVENTS: 'special-events',
    KIDS_NOW_SHOWING: 'kids-corner/now-showing',
    KIDS_COMING_SOON: 'kids-corner/coming-soon',
    KIDS_ACTIVITIES: 'kids-corner/activities',
    MOVIE_DETAILS: ':cinemaId/movies/details/:movieGroupId',
    MOVIE_DETAILS_SLUG: ':cinemaId/movies/details/:movieSlug',
    MOVIE_DETAIL_SEARCH: 'movies/details/:cinemaId/:movieGroupId',
    MOVIE_DETAILS_COMING_SOON: ':cinemaId/movies/details',
    PRINT_SESSIONS: 'printsession',
    CINEMAS: 'cinemas',
    GIFTSHOP: 'giftcards',
    ECARDS: 'e-gift-cards',
    PHYSICAL_CARDS: 'physical-gift-cards',
    EXPERIENCE: 'experience',
    FAQ: 'faq',
    OFFERS_EVENTS: 'offersAndEvents',
    TICKETS: 'sessions/:sessionId/:movieId',
    TICKET_BOOKING: '/cinemas/:cinemaId/sessions/:sessionId/:movieId',
    FOOD_AND_BEVERAGE: 'cinemas/:cinemaId/foodandbevarages',
    HISTORY:'history',
    ABOUT:'about',
    ADVERTISING:'advertising',
    REELCLUB:'reelclub',
    CINEMONY: 'cinemony',
    CINEMONYMODAL: '/cinemony',  
    COMPETITIONS:'competitions',
    MAILINGLIST:'mailinglist',
    MAILINGLISTMODAL: '/mailinglist',
    CAFEBAR: 'cafe-bar',
    HIGHLINE_TERRACE:'highline-terrace',
    MEMBERSHIP: 'membership',
    LOYALTY_MEMBERSHIP: 'loyalty-membership',
    SOHOLOUNGE: 'soho-lounge',
    GAMING_HIRE: 'gaming-hire',
    SEARCH_RESULT: 'search-result',
    SITE_DISCLAIMER: 'site-disclaimer',
    REWARDS:'rewards',
    //US
    HOME: ':cinemaId',
    EAT_AND_DRINK: 'eat-and-drink',
    REFUND: 'refund/:sharedUserSessionId',
    SWAP_SEAT: 'seat-swap/:sharedUserSessionId',
    SOCIAL_SHARE: 'sessions/:sessionId/:movieId/:sharedUserSessionId',
    SIGNATURE_PROGRAMS: ':cinemaId/signature-programming',
    SIGNATURE_PROGRAM: 'signature-programming/:uniqueKey',
    SPECIAL_VALUES: 'special-values',
    SPECIAL_VALUE: 'special-values/:uniqueKey',
    ADVERTISE: 'advertise-with-us',
    FAQ_US: ':cinemaId/faq',
    CONTACT_US: 'contact-us',
    CONTACT_US_AU: 'contact_us',
    PRIVACY: 'privacy',
    NOTICE: 'notice',
    PRIVACY_POLICY_AU:'privacy-policy',
    PRIVACY_POLICY_US_STATE: 'us-state',
    PRIVACY_POLICY_CHILDREN: 'children',
    INVESTORS_BASED_ADS: 'investor',
    PERSONAL_INFORMATION: 'personal-information',
    TERMS: 'terms',
    GIFT_CARD_TERMS: 'giftcard-terms',
    ETIX_TERMS: 'etix-terms',
    RATING: 'rating',
    NEWS_LETTER: 'newsletter',
    CAREER: 'work-at-reading-cinemas',
    CAREERS: 'careers',
    GROUP_SALE: 'group-sale',
    CINEMA_HIRE: 'cinema-hire',
    VIP_EXPERIENCE: 'vip-cinemas',
    GIFT_CART: 'my-cart',
    TITAN_FLAG: ':titanFlag',
    ACCESSIBILITY : 'accessibility',
    RESET_PASSWORD: "account/resetPassword",
    CONFIRM_USER:"account/confirmUser",
    SEARCH:'search',
    CUSTOM_PAGE: 'pages/:page',
    PAGE_NOT_FOUND: 'page-not-found',
    REGISTRATION: "account/registration",
    NOW_PLAYING: "now-playing",
    ADVANCE_TICKETS: "advance-tickets",
    FOOD_IAM_HERE:"am-here/:code",
    MEMBERSHIP_SIGN_UP: "account/signup",
    PROFILE_TICKETS: "my-tickets",
    PROFILE_REWARDS: "my-rewards",
    PROFILE_PAYMENT_METHODS: "my-payment-method",
    PROFILE_GIFT_CARDS: "my-gift-cards",
    PROFILE_UPDATE: "my-profile",
    CINEMA_EXTRAS: "cinema-extras",
    UPDATE_PROFILE: "update-profile"

}
