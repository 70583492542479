import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Modal from "../../../UI/Modal";
import {
  logoutAction,
  memberValidationWatcher,
  showUpdateProfileModal,
} from "../../../../store/auth/action";
import { IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";
import "./updateProfile.scss";
import {
  BRANDS,
  COUNTRY,
  GENDER_LIST,
  GENDER_LIST_STA,
  GENDER_LIST_US,
  GENRE_LIST,
  MONTH_LIST,
} from "../../../../constants/app";
import moment from "moment";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../utils/validatorSchema";
import TextInput from "../../../UI/TextInput";
import SelectDropdown from "../../../UI/DropDown/SelectDropDown";
import {
  dateArray,
  numberOnly,
  yearArray,
  yearSignupArray,
} from "../../../../utils/helper";
import {
  changePasswordApi,
  getMemberCardlessApi,
  logOutApi,
  memberUpdateApi,
  memberValidationApi,
  updateMemberAttributeApi,
} from "../../../../services/auth";
import { memberValidationSuccess } from "../../../../store/auth/action";
import { setUserDetails } from "../../../../store/auth/action";
import { toast } from "react-toastify";
import { openSliderAction } from "../../../../store/application/action";

interface IUpdateProfileProps { }

const relationship = [
  { value: "Self", label: "Self" },
  { value: "Spouse", label: "Spouse" },
  { value: "Father", label: "Father" },
  { value: "Mother", label: "Mother" },
  { value: "Son", label: "Son" },
  { value: "Daughter", label: "Daughter" },
];

const militarystatus = [
  { value: "Active", label: "Active" },
  { value: "InActive", label: "InActive" },
];

const UpdateProfile: FC<IUpdateProfileProps> = () => {
  const {
    memberDetail,
    openUpdateProfileModal,
    userDetails,
    countryId,
    brandId,
    currentCinema,
    cinemas
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    memberDetail: state.authReducer.memberDetail,
    userDetails: state.authReducer.userDetails,
    openUpdateProfileModal: state.authReducer.openUpdateProfileModal,
    brandId: state.applicationReducer.brandId,
    currentCinema: state.applicationReducer.currentCinema,
    cinemas: state.applicationReducer.cinemas,
  }));

  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [genderList, setGenderList] = useState<{ label: string; value: any }[]>(
    []
  );

  useEffect(() => {
    if ([COUNTRY.AUS, COUNTRY.NZ].includes(countryId)) {
      setGenderList(GENDER_LIST);
    } else if ([COUNTRY.STA, COUNTRY.ANG].includes(countryId)) {
      setGenderList(GENDER_LIST_STA);
    } else if ([COUNTRY.US, COUNTRY.CON, COUNTRY.AFC].includes(countryId)) {
      setGenderList(GENDER_LIST_US);
    }
  }, [countryId]);

  const cinemaList = useMemo(() => {
    const allCinemaLocation: { label: string; value: string }[] = [];
    Object.keys(cinemas).forEach(function (key) {
      const arrList = cinemas[key];
      arrList.forEach((obj: any) => {
        allCinemaLocation.push({
          value: obj.slug,
          label:
            countryId === COUNTRY.AUS || countryId === COUNTRY.NZ
              ? obj.cinemaName + ", " + key
              : obj.cinemaName,
        });
      });
    });
    return allCinemaLocation;
  }, [cinemas]);

  const onCloseModal = () => {
    dispatch(showUpdateProfileModal(false));
  };

  const hasCard = useMemo(() => {
    return (userDetails?.cardNumber && userDetails?.vista_memberid) ? true : false;
  }, [userDetails]);

  const updateProfile = async (payload: any) => {
    const {
      response: {
        data: {
          data: { status, memberId, errorMessage },
          statusCode,
        },
      },
    } = (await updateMemberAttributeApi(payload)) as any;
    if (status === "true" && statusCode === 200) {
      let memberID = memberId;

      const memberFormValues = {
        MemberRefId: userDetails?.vista_memberid,
        countryId: countryId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: userDetails?.email,
        updatedEmail: values.email,
        gender: values.gender,
        phoneNumber: values.phoneNumber,
        zipCode: values.postCode,
        dateOfBirth: `${values.year}-${values.month}-${values.day}`,
        suburb: values.suburb,
        city: values.city,
        cardNumber: userDetails?.cardNumber,
        memberId: memberId,
        preferredGenres: values.genres,
        weeklyNews: values.weeklyNews,
      };

      const {
        response: {
          data: {
            data: { ErrorDescription },
          },
        },
      } = (await memberUpdateApi(memberFormValues)) as any;

      let {
        response: {
          data: {
            data,
            statusCode
          },
        },
      } = (await getMemberCardlessApi({
        memberRefId: memberID,
        emailId: values.email,
      })) as any;
      if (statusCode === 200) {
        const {
          Email,
          FirstName,
          LastName,
          CardNumber,
          Country_id,
          Theatre_id,
          Weekly_news,
          Online,
          vista_memberid,
          ZipCode,
          Gender,
          MobilePhone,
          DateOfBirth,
          Suburb,
        } = data;

        dispatch(
          setUserDetails({
            ...userDetails,
            countryId: countryId,
            email: Email,
            firstName: FirstName,
            id: memberID,
            lastName: LastName,
            phoneNumber: MobilePhone,
            cardNumber: CardNumber,
            weekly_news: Weekly_news,
            theatre_id: Theatre_id,
            online: Online,
            vista_memberid: vista_memberid,
            zipCode: ZipCode,
            dateOfBirth: DateOfBirth
              ? moment(DateOfBirth, 'YYYY-MM-DD').format(
                "YYYY-MM-DD"
              )
              : "",
            gender: Gender,
            preferredGenres: values.genres.join(","),
            Suburb: Suburb,
            city: Suburb,
            isMilitary: payload.isMilitary ? 1 : 0
          })
        );
      }
      const memberpayload = {
        MemberRefId: userDetails?.vista_memberid,
        UserSessionId: userDetails?.result?.cardNumber,
        cardNumber: userDetails?.result?.cardNumber,
        countryId: countryId,
        memberId: userDetails?.id,
        emailId: userDetails?.email,
      };
      dispatch(memberValidationWatcher(memberpayload));
      if (values.newPassword) {
        toast.success(
          "Your profile and password has been updated successfully. Please login again"
        );
        dispatch(showUpdateProfileModal(false));
        const { status } = await logOutApi();
        if (status === 200) {
          dispatch(logoutAction({}));
          dispatch(openSliderAction({ open: false, type: "" }));
        }
      } else {
        toast.success("Your profile has been updated successfuly!!!");
        dispatch(showUpdateProfileModal(false));
      }
    } else {
      setErrorMessage(errorMessage);
    }
  };

  const showMilitary = useMemo(() => {
    return brandId === BRANDS.US;
  }, [brandId]);

  const { handleChange, handleSubmit, values, errors, touched, setFieldValue } =
    useFormik({
      validationSchema: createValidationSchema("updateProfile"),
      enableReinitialize: true,
      initialValues: {
        brandId: brandId,
        firstName: userDetails?.firstName || "",
        lastName: userDetails?.lastName || "",
        email: userDetails?.email || "",
        phoneNumber: userDetails?.phoneNumber
          ? userDetails?.phoneNumber.replace(/[^+\d]+/g, "").replace("+", "")
          : "",
        suburb: userDetails?.Suburb || "",
        city: userDetails?.Suburb || "",
        gender: userDetails?.gender || "",
        genres:
          [parseInt((`${userDetails?.preferredGenres}`).replace(/\[|\]/g, ''))],
        cinemaId: userDetails?.theatre_id || "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        postCode: userDetails?.zipCode || "",
        day: userDetails?.dateOfBirth
          ? moment(userDetails.dateOfBirth, 'YYYY-MM-DD').format("DD")
          : "",
        month: userDetails?.dateOfBirth
          ? moment(userDetails.dateOfBirth, 'YYYY-MM-DD').format("MM")
          : "",
        year: userDetails?.dateOfBirth
          ? moment(userDetails.dateOfBirth, 'YYYY-MM-DD').format("YYYY")
          : "",
        weeklyNews: userDetails?.weekly_news ? 1 : 0,
        militaryMemberId: memberDetail?.additionalUser?.[0]?.military_memberid,
        isMilitary: showMilitary && userDetails?.isMilitary ? true : false,
        militaryTerms: memberDetail?.additionalUser?.[0]?.member_id
          ? true
          : false,
        relationship:
          memberDetail?.additionalUser?.[0]?.military_member_relationship || "",
        militaryFirstName: memberDetail?.additionalUser?.[0]?.First_name || "",
        militaryLastName: memberDetail?.additionalUser?.[0]?.Last_name || "",
        militaryMiddleName:
          memberDetail?.additionalUser?.[0]?.Middle_name || "",
        militaryDay:
          memberDetail?.additionalUser?.length! > 0
            ? moment(memberDetail?.additionalUser?.[0]?.dob).format("DD")
            : "",
        militaryMonth:
          memberDetail?.additionalUser?.length! > 0
            ? moment(memberDetail?.additionalUser?.[0]?.dob).format("MM")
            : "",
        militaryYear:
          memberDetail?.additionalUser?.length! > 0
            ? moment(memberDetail?.additionalUser?.[0]?.dob).format("YYYY")
            : "",
        militaryCity: memberDetail?.additionalUser?.[0]?.city || "",
        militaryZipCode: memberDetail?.additionalUser?.[0]?.ZipCode || "",
      },
      onSubmit: async (values) => {
        const changePasswordData = {
          email: values.email,
          oldPassword: values.currentPassword,
          newPassword: values.newPassword,
        };
        const updateProfilePayload: any = {
          PreferredGenres: values.genres.join(","),
          countryId: countryId,
          dateOfBirth: `${values.year}-${values.month}-${values.day}`,
          email: values.email,
          firstName: values.firstName,
          gender: values.gender,
          id: userDetails?.id,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          suburb: values.suburb,
          updatedEmail: values.email,
          weeklyNews: values.weeklyNews,
          zipCode: values.postCode,
          city: values.city,
          isMilitary: values.isMilitary,
          theatreId: values.cinemaId,
        };

        if (showMilitary) {
          if (
            memberDetail?.additionalUser?.[0]?.member_id &&
            userDetails?.isMilitary &&
            memberDetail?.additionalUser?.[0]?.status === 0
          ) {
            updateProfilePayload.additionalUserUpdate = {
              id: memberDetail?.additionalUser?.[0]?.member_id,
              firstName: values.militaryFirstName,
              lastName: values.militaryLastName,
              middleName: values.militaryMiddleName,
              relationship: values.relationship,
              dateOfBirth: `${values.militaryYear}-${values.militaryMonth}-${values.militaryDay}`,
              city: values.militaryCity,
              ZipCode: values.militaryZipCode,
              countryId: countryId,
              theatreId: values.cinemaId,
              militaryMemberId: values.militaryMemberId,
              email: values.email,
            };
          } else if (
            values?.isMilitary &&
            memberDetail?.additionalUser?.length === 0
          ) {
            updateProfilePayload.additionalUser = {
              firstName: values.militaryFirstName,
              lastName: values.militaryLastName,
              middleName: values.militaryMiddleName,
              relationship: values.relationship,
              dateOfBirth: `${values.militaryYear}-${values.militaryMonth}-${values.militaryDay}`,
              city: values.militaryCity,
              ZipCode: values.militaryZipCode,
              countryId: countryId,
              theatreId: values.cinemaId,
              militaryMemberId: values.militaryMemberId,
              email: values.email,
            };
          }
        }

        if (values.currentPassword && values.newPassword) {
          const {
            data: {
              data: { status },
              statusCode,
            },
          } = await changePasswordApi(changePasswordData);
          if (status && statusCode === 200) {
            updateProfile(updateProfilePayload);
          }
        } else {
          updateProfile(updateProfilePayload);
        }
      },
    });

  const onChangeWeeklyLetter = (event: any) => {
    const {
      target: { checked },
    } = event;
    setFieldValue("weeklyNews", checked ? 1 : 0);
  };

  const onChangeMilitaryTerms = (event: any) => {
    const {
      target: { checked },
    } = event;
    setFieldValue("militaryTerms", checked ? true : false);
  };

  const onToggle = () => {
    setFieldValue("isMilitary", !values.isMilitary);
  };

  const onChangeGenre = (event: any) => {
    setFieldValue("genres", [event]);
  };

  const onInputChange = (e: any, field: string) => {
    const value = numberOnly(e);
    if (value !== undefined) {
      setFieldValue(field, value);
    }
  };

  const formFields =
    (<>
      <div className="account_details_form form_page">
        <div className="row">
          <div className="col-6 col-md-6 ">
            <label className="form-label">First Name*</label>
            <div className="form-group">
              <TextInput
                value={values.firstName}
                field={"firstName"}
                handleChange={handleChange}
                touched={touched.firstName!}
                error={errors.firstName}
              />
            </div>
          </div>
          <div className="col-6 col-md-6">
            <label className="form-label">Last Name*</label>
            <div className="form-group">
              <TextInput
                value={values.lastName}
                field={"lastName"}
                handleChange={handleChange}
                touched={touched.lastName!}
                error={errors.lastName}
              />
            </div>
          </div>
          <div className="col-6 col-md-6">
            <label className="form-label">Gender*</label>
            <div className="form-group">
              <SelectDropdown
                field={"gender"}
                options={genderList}
                setFieldValue={setFieldValue}
                touched={touched.gender!}
                error={errors.gender}
                value={values.gender}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <label className="form-label">Date of Birth*</label>
            <div className="form-group">
              <div
                className={`row ${brandId === BRANDS.US ? "date-of-birth-order" : ""
                  }`}
              >
                <div className="col-4 col-md-4">
                  <SelectDropdown
                    field={"day"}
                    options={dateArray().map((v) => {
                      v.label = Math.round(+v.label).toString();
                      return v;
                    })}
                    setFieldValue={setFieldValue}
                    touched={touched.day!}
                    error={errors.day}
                    value={values.day}
                  />
                </div>
                <div className="col-4 col-md-4">
                  <SelectDropdown
                    field={"month"}
                    options={MONTH_LIST}
                    setFieldValue={setFieldValue}
                    touched={touched.month!}
                    error={errors.month}
                    value={values.month}
                  />
                </div>
                <div className="col-4 col-md-4">
                  <SelectDropdown
                    field={"year"}
                    options={yearArray()}
                    setFieldValue={setFieldValue}
                    touched={touched.year!}
                    error={errors.year}
                    value={values.year}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <label className="form-label">Mobile Phone*</label>
            <div className="form-group position-relative">
              <TextInput
                value={values.phoneNumber}
                field={"phoneNumber"}
                handleChange={handleChange}
                touched={touched.phoneNumber!}
                error={errors.phoneNumber}
              />
            </div>
          </div>
          {brandId === BRANDS.US ? (
            <div className="col-12 col-md-3">
              <label className="form-label">City*</label>
              <div className="form-group">
                <TextInput
                  value={values.city}
                  field={"city"}
                  handleChange={handleChange}
                  touched={touched.city!}
                  error={errors.city}
                />
              </div>
            </div>
          ) : null}

          <div className="col-12 col-md-3">
            <label className="form-label">
              {brandId === BRANDS.US ? "ZIP Code" : "Post Code"}*
            </label>
            <div className="form-group">
              <TextInput
                value={values.postCode}
                field={"postCode"}
                touched={touched.postCode!}
                error={errors.postCode}
                maxLength={5}
                onChange={(e: any) => onInputChange(e, "postCode")}
              />
            </div>
          </div>
          {brandId === BRANDS.AU ? (
            <div className="col-12 col-md-3">
              <label className="form-label">Suburb*</label>
              <div className="form-group">
                <TextInput
                  value={values.suburb}
                  field={"suburb"}
                  handleChange={handleChange}
                  touched={touched.suburb!}
                  error={errors.suburb}
                />
              </div>
            </div>
          ) : null}
          {brandId === BRANDS.US ? (
            <>
              <div className="col-12 col-md-6">
                <label className="form-label">Preferred Genre*</label>
                <div className="form-group">
                  <SelectDropdown
                    field={"genres"}
                    options={GENRE_LIST}
                    onChange={onChangeGenre}
                    touched={touched.genres!}
                    error={errors.genres}
                    value={
                      Array.isArray(values.genres) &&
                        values.genres.length > 0
                        ? values.genres[0]
                        : values.genres
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <label className="form-label">Preferred Location*</label>
                <div className=" form-group">
                  <SelectDropdown
                    field={"cinemaId"}
                    options={cinemaList}
                    setFieldValue={setFieldValue}
                    touched={touched.cinemaId!}
                    error={errors.cinemaId}
                    value={values.cinemaId}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="col-12 col-md-6">
              <label className="form-label">Preferred Genres*</label>
              <div className="form-group">
                <SelectDropdown
                  field={"genres"}
                  options={GENRE_LIST}
                  setFieldValue={setFieldValue}
                  touched={touched.genres!}
                  error={errors.genres}
                  value={values.genres}
                  isMulti={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="profile-header p-t-20">
        <div className="title">Account Details</div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <label className="form-label">Email Address*</label>
          <div className="form-group">
            <TextInput
              value={values.email}
              field={"email"}
              handleChange={handleChange}
              touched={touched.email!}
              error={errors.email}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Current Password</label>
          <div className="form-group">
            <TextInput
              autoComplete="disabled"
              type="password"
              value={values.currentPassword}
              field={"currentPassword"}
              handleChange={handleChange}
              touched={touched.currentPassword!}
              error={errors.currentPassword}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">New Password</label>
          <div className="form-group">
            <TextInput
              type="password"
              value={values.newPassword}
              field={"newPassword"}
              handleChange={handleChange}
              touched={touched.newPassword!}
              error={errors.newPassword}
            />
            <span className="hint">Ex:Johnpeter@123</span>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">Confirm Password</label>
          <div className="form-group">
            <TextInput
              type="password"
              value={values.confirmPassword}
              field={"confirmPassword"}
              handleChange={handleChange}
              touched={touched.confirmPassword!}
              error={errors.confirmPassword}
            />
          </div>
        </div>
        {showMilitary &&
          memberDetail &&
          memberDetail?.additionalUser &&
          memberDetail?.additionalUser.length > 0 &&
          userDetails?.isMilitary === 1 ? (
          <div className="col-12 my-2">
            <h6 className="d-flex">
              <span className="cursor-pointer">
                Military Identification
              </span>
            </h6>
            {memberDetail?.additionalUser?.[0]?.status === 0 ? (
              <p className="d-flex align-items-center">
                <img
                  style={{ width: 14 }}
                  src={
                    IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "clock.png"
                  }
                />
                <span className="mx-2">
                  Military Identification submitted. Please allow up to
                  5 business days for verification.
                </span>
              </p>
            ) : null}
            {memberDetail?.additionalUser?.[0]?.status === 1 ? (
              <p className="d-flex align-items-center">
                <img
                  style={{ width: 14 }}
                  src={
                    IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "selected.png"
                  }
                />
                <span className="mx-2">
                  Military Identification verified.
                </span>
              </p>
            ) : null}
            {memberDetail?.additionalUser?.[0]?.status === 2 ? (
              <p className="d-flex align-items-center">
                <img
                  style={{ width: 14 }}
                  src={
                    IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "clock.png"
                  }
                />
                <span className="mx-2">
                  Military Identification verification failed.
                </span>
              </p>
            ) : null}
          </div>
        ) : null}
        {(showMilitary && !memberDetail?.additionalUser?.length!) ||
          userDetails?.isMilitary === 0 ? (
          <div className="col-12 my-2">
            <div className="add-additional-member">
              {!values.isMilitary ? (
                <h6
                  className="d-flex justify-content-center m-0 cursor-pointer align-items-center"
                  onClick={onToggle}
                >
                  <span className="col-md-12 btn_plus"></span>
                  <span className="mx-2 add-military-label">
                    ADD MILITARY IDENTIFICATION
                  </span>
                </h6>
              ) : (
                <>
                  <div className="additional_member_wrapper">
                    <h6 className="d-flex">
                      <span className="cursor-pointer" onClick={onToggle}>
                        Add Military Identification
                      </span>
                    </h6>
                    <div className="row">
                      <div className="col-12 col-md-4 ">
                        <label className="form-label">
                          Relationship*
                        </label>
                        <div className="form-group">
                          <SelectDropdown
                            field={"relationship"}
                            options={relationship}
                            setFieldValue={setFieldValue}
                            touched={touched.relationship!}
                            error={errors.relationship}
                            value={values.relationship}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label className="form-label">Military ID*</label>
                        <div className="form-group">
                          <TextInput
                            type="text"
                            value={values.militaryMemberId}
                            field={"militaryMemberId"}
                            handleChange={handleChange}
                            touched={touched.militaryMemberId!}
                            error={errors.militaryMemberId}
                          />
                        </div>
                      </div>

                      <div className="col-4 col-md-4 ">
                        <label className="form-label">First Name*</label>
                        <div className="form-group">
                          <TextInput
                            value={values.militaryFirstName}
                            field={"militaryFirstName"}
                            handleChange={handleChange}
                            touched={touched.militaryFirstName!}
                            error={errors.militaryFirstName}
                          />
                        </div>
                      </div>

                      <div className="col-4 col-md-4">
                        <label className="form-label">
                          Middle Name(if Any)
                        </label>
                        <div className="form-group">
                          <TextInput
                            value={values.militaryMiddleName}
                            field={"militaryMiddleName"}
                            handleChange={handleChange}
                            touched={touched.militaryMiddleName!}
                            error={errors.militaryMiddleName}
                          />
                        </div>
                      </div>

                      <div className="col-4 col-md-4">
                        <label className="form-label">Last Name*</label>
                        <div className="form-group">
                          <TextInput
                            value={values.militaryLastName}
                            field={"militaryLastName"}
                            handleChange={handleChange}
                            touched={touched.militaryLastName!}
                            error={errors.militaryLastName}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-8">
                        <label className="form-label">
                          Date of Birth*
                        </label>
                        <div className="form-group">
                          <div
                            className={`row ${brandId === BRANDS.US
                              ? "date-of-birth-order"
                              : ""
                              }`}
                          >
                            <div className="col-4 col-md-4">
                              <SelectDropdown
                                field={"militaryDay"}
                                options={dateArray().map((v) => {
                                  v.label = Math.round(
                                    +v.label
                                  ).toString();
                                  return v;
                                })}
                                setFieldValue={setFieldValue}
                                touched={touched.militaryDay!}
                                error={errors.militaryDay}
                                value={values.militaryDay}
                              />
                            </div>
                            <div className="col-4 col-md-4">
                              <SelectDropdown
                                field={"militaryMonth"}
                                options={MONTH_LIST}
                                setFieldValue={setFieldValue}
                                touched={touched.militaryMonth!}
                                error={errors.militaryMonth}
                                value={values.militaryMonth}
                              />
                            </div>
                            <div className="col-4 col-md-4">
                              <SelectDropdown
                                field={"militaryYear"}
                                options={yearSignupArray()}
                                setFieldValue={setFieldValue}
                                touched={touched.militaryYear!}
                                error={errors.militaryYear}
                                value={values.militaryYear}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-3 col-md-4">
                        <label className="form-label">City*</label>
                        <div className="form-group position-relative">
                          <TextInput
                            value={values.militaryCity}
                            field={"militaryCity"}
                            handleChange={handleChange}
                            touched={touched.militaryCity!}
                            error={errors.militaryCity}
                          />
                        </div>
                      </div>
                      <div className="col-3 col-md-3">
                        <label className="form-label">Zip Code*</label>
                        <div className="form-group">
                          <TextInput
                            value={values.militaryZipCode}
                            field={"militaryZipCode"}
                            touched={touched.militaryZipCode!}
                            error={errors.militaryZipCode}
                            maxLength={5}
                            onChange={(e: any) =>
                              onInputChange(e, "militaryZipCode")
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="custom_checkbox p-default form-group custom-check-border">
                          <input
                            type="checkbox"
                            value="1"
                            onClick={onChangeMilitaryTerms}
                            checked={values.militaryTerms}
                          />
                          <div className="state p-primary">
                            <label className="checklast">
                              <i>
                                I understand that the information provided
                                above is accurate and will be checked for
                                military validity.
                              </i>
                            </label>
                          </div>
                        </div>
                        {touched.militaryTerms &&
                          errors?.militaryTerms ? (
                          <div className="err-message">
                            Please select the terms and condition
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : null}
        <div className="col-md-12">
          <div className="custom_checkbox p-default form-group">
            <input
              type="checkbox"
              value="1"
              onClick={onChangeWeeklyLetter}
              checked={values.weeklyNews === 1 ? true : false}
            />
            <div className="state p-primary">
              <label className="form-label">
                {countryId === COUNTRY.ANG
                  ? "Keep me updated with Angelika offers, special events and weekly news"
                  : countryId === COUNTRY.STA
                    ? `Keep me updated with Loyalty weekly news`
                    : brandId === BRANDS.US
                      ? `Keep me updated with movies, events, and offers`
                      : `Keep me updated with Reel Club weekly news`}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        {errorMessage ? <div className="text-center error_message">{errorMessage}</div> : null}
      </div>
      <div className="col-md-12 movie_footer_btn_el accounts_btn">
        <div className="movie_button_wrap">
          <button
            className="btn black_btn"
            type="button"
            onClick={() => handleSubmit()}
          >
            UPDATE
          </button>
        </div>
      </div>
    </>)

  return (
    <>
      {countryId === COUNTRY.AFC ? (
        <div className="profile-wrapper">
          <div>
            <div className="profile-header">
              <div className="title title_append">Update User Details</div>
            </div>
            <div className="profile-body">
              {formFields}
            </div>
          </div>
        </div>
      ) : (
        <Modal
          visible={openUpdateProfileModal}
          className="update-profile-popup"
          showCloseBtn={false}
        >
          <div className="modal fade show" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered modal-lg ">
              <div className="modal-content">
                <button
                  type="button"
                  className="close-modal"
                  onClick={onCloseModal}
                >
                  <img
                    className="close_icon"
                    src={URLS.CLOSE_ICON}
                    alt="close_icon"
                    title="close_icon"
                  />
                </button>
                <div className="modal-header">
                  <div className="title title_append">UPDATE USER DETAILS</div>
                </div>
                <div className="modal-body">
                  {formFields}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default UpdateProfile;
