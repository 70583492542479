import React, { FC, lazy } from "react";
import { Routes, Route } from "react-router";
import { Navigate } from "react-router-dom";
import PublicRoutes from "../../components/Routes/PublicRoutes";
import { ROUTES } from "../../constants/path";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { BRANDS } from "../../constants/app";
import AULayout from "../../layout/AU";
import USLayout from "../../layout/US";
import Home from "../US/Home";
import CinemaExtras from "../Common/ContentPage/CinemaExtras";
import UpdateProfile from "../Common/ContentPage/UpdateProfile";

const Cinemas = lazy(() => import('../AU/Cinemas'));
const GiftShop = lazy(() => import('../Common/GiftShop'));
const GiftShopUs = lazy(() => import('../Common/GiftShop/index_us'));
const GiftCards = lazy(() => import('../Common/GiftShop/Cards'));
const Movies = lazy(() => import('../AU/Movies'));
const Activities = lazy(() => import('../AU/Movies/Activities'));
const ComingSoon = lazy(() => import('../AU/Movies/ComingSoon'));
const MovieDetails = lazy(() => import('../AU/Movies/MovieDetails'));
const NowShowing = lazy(() => import('../AU/Movies/NowShowing'));
const PrintSession = lazy(() => import('../AU/Movies/PrintSession'));
const SpecialEvents = lazy(() => import('../AU/Movies/SpecialEvents'));
const Deals = lazy(() => import('../Common/ContentPage/Deals'));
const Faq = lazy(() => import('../Common/ContentPage//Faq'));
const FoodAndBeverage = lazy(() => import('../Common/FoodAndBeverage'));
const UsMovieDetails = lazy(() => import('../US/MovieDetails'));
const Ticketing = lazy(() => import('../Common/Ticketing'));
const SignaturePrograming = lazy(() => import('../US/SignatureProgramming'));
const SpecialValueProgram = lazy(() => import('../US/SpecialValueProgram'));
const Rewards = lazy(() => import('../Common/Membership'));
const AdvertiseWithUs = lazy(() => import('../US/AdvertiseWithUs'));
const ContactUs = lazy(() => import('../Common/ContentPage/ContactUs'));
const PrivacyPolicy = lazy(() => import('../Common/ContentPage/PrivacyPolicy'));
const Terms = lazy(() => import('../Common/ContentPage/Terms'));
const GiftCardTerms = lazy(() => import('../Common/ContentPage/GiftCardTerms'));
const EtixTerms = lazy(() => import('../Common/ContentPage/EtixTerms'));
const RatingIndex = lazy(() => import('../Common/ContentPage/RatingIndex'));
const Careers = lazy(() => import('../US/Careers'));
const GroupSale = lazy(() => import('../Common/ContentPage/GroupSale'));
const CinemaHire = lazy(() => import('../Common/ContentPage/CinemaHire'));
const VipCinemas = lazy(() => import('../Common/ContentPage/VipCinemas'));
const GiftCart = lazy(() => import('../Common/GiftShop/Cart'));
const TitanMovies = lazy(() => import('../US/TitanMovies'));
const Accessibility = lazy(() => import('../US/Accessibility'));
const ResetPassword = lazy(() => import('../Common/ContentPage/ResetPassword'));
const ConfirmUser = lazy(() => import('../Common/ContentPage/ConfirmUser'));
const NewsLetter = lazy(() => import('../Common/ContentPage/NewsLetter'));
const SearchUS = lazy(() => import('../US/Search'));
const GamingHires = lazy(() => import('../Common/ContentPage/GamingHire'));
const History = lazy(() => import('../Common/ContentPage/History'));
const ReelClub = lazy(() => import('../Common/ContentPage/ReelClub'));
const Competitions = lazy(() => import('../Common/ContentPage/Competitions'));
const CafeAndBar = lazy(() => import('../Common/ContentPage/CafeAndBar'));
const Advertising = lazy(() => import('../Common/ContentPage/Advertising'));
const SohoLounge = lazy(() => import('../Common/ContentPage/SohoLounge'));
const CustomPage = lazy(() => import('../US/CustomPage'));
const PageNotFound = lazy(() => import('../Common/PageNotFound'));
const SearchAU = lazy(() => import('../AU/Search'));
const About = lazy(() => import('../Common/ContentPage/About'));
const HighlineTerrace = lazy(() => import('../Common/ContentPage/HighlineTerrace'));
const Registration = lazy(() => import('../Common/ContentPage/Registration'));
const FoodCollect = lazy(() => import('../Common/Modals/FoodCollect'));
const MembershipSignin = lazy(() => import('../Common/Membership/signup'));
const MyTickets = lazy(() => import('../US/Profile/MyTickets'));
const MyRewards = lazy(() => import('../US/Profile/Rewards'));
const MyPaymentMethods = lazy(() => import('../US/Profile/PaymentMethods'));
const MyGiftCards = lazy(() => import('../US/Profile/MyGiftCards'));
const MyProfileUpdate = lazy(() => import('../US/Profile/MyProfileUpdate'));

interface RoutesProps {}

const AppRoutes: FC<RoutesProps> = () => {
  const { brandId, currentCinema } = useSelector((state: RootState) => ({
    brandId: state.applicationReducer.brandId,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  return brandId === BRANDS.AU ? (
    <Routes>
      {/* Movie Pages */}
      <Route
        path={ROUTES.MOVIES}
        element={<PublicRoutes layout={AULayout} component={Movies} />}
      >
        <Route path={ROUTES.NOW_SHOWING} element={<NowShowing />} />
        <Route path={ROUTES.COMING_SOON} element={<ComingSoon />} />
        <Route path={ROUTES.SPECIAL_EVENTS} element={<SpecialEvents />} />
        <Route path={ROUTES.KIDS_NOW_SHOWING} element={<NowShowing />} />
        <Route path={ROUTES.KIDS_COMING_SOON} element={<ComingSoon />} />
        <Route path={ROUTES.KIDS_ACTIVITIES} element={<Activities />} />
      </Route>
      <Route
        path={ROUTES.MOVIE_DETAILS}
        element={<PublicRoutes layout={AULayout} component={MovieDetails} />}
      />
      <Route
        path={ROUTES.MOVIE_DETAIL_SEARCH}
        element={<PublicRoutes layout={AULayout} component={MovieDetails} />}
      />
      <Route path={ROUTES.PRINT_SESSIONS} element={<PrintSession />}></Route>

      {/* Ticket Booking*/}
      <Route
        path={ROUTES.TICKET_BOOKING}
        element={<PublicRoutes layout={AULayout} component={Ticketing} />}
      ></Route>

      <Route
        path={`${ROUTES.HOME}/${ROUTES.PAGE_NOT_FOUND}`}
        element={<PublicRoutes layout={AULayout} component={PageNotFound} />}
      />
      {/* Food and beverage */}
      <Route
        path={ROUTES.FOOD_AND_BEVERAGE}
        element={<PublicRoutes layout={AULayout} component={Movies} />}
      >
        <Route path={""} element={<FoodAndBeverage />} />
      </Route>

      {/* Cinemas */}
      <Route
        path={ROUTES.CINEMAS}
        element={<PublicRoutes layout={AULayout} component={Cinemas} />}
      ></Route>

      {/* vip experience */}
      <Route
        path={`${ROUTES.VIP_EXPERIENCE}`}
        element={<PublicRoutes layout={AULayout} component={VipCinemas} />}
      />
      {/* history */}
      <Route
        path={`${ROUTES.HISTORY}`}
        element={<PublicRoutes layout={AULayout} component={History} />}
      />
      <Route
        path={`${ROUTES.ABOUT}`}
        element={<PublicRoutes layout={AULayout} component={About} />}
      />

      {/* Membership */}
      <Route
        path={`${ROUTES.MEMBERSHIP}`}
        element={<PublicRoutes layout={AULayout} component={ReelClub} />}
      />
      <Route
        path={`${ROUTES.REWARDS}`}
        element={<PublicRoutes layout={AULayout} component={Rewards} />}
      />

      {/* Cafe & Bar */}
      <Route
        path={`${ROUTES.CAFEBAR}`}
        element={<PublicRoutes layout={AULayout} component={CafeAndBar} />}
      />
      <Route
        path={`${ROUTES.HIGHLINE_TERRACE}`}
        element={<PublicRoutes layout={AULayout} component={HighlineTerrace} />}
      />

      {/* advertising */}
      <Route
        path={`${ROUTES.ADVERTISING}`}
        element={<PublicRoutes layout={AULayout} component={Advertising} />}
      />
      {/* reel club */}
      <Route
        path={`${ROUTES.REELCLUB}`}
        element={<PublicRoutes layout={AULayout} component={ReelClub} />}
      />
      {/* Search */}
      <Route
        path={`${ROUTES.SEARCH_RESULT}`}
        element={<PublicRoutes layout={AULayout} component={SearchAU} />}
      />

      {/* Competitions */}
      <Route
        path={`${ROUTES.COMPETITIONS}`}
        element={<PublicRoutes layout={AULayout} component={Competitions} />}
      />
      {/* mailinglist */}
      <Route
        path={`${ROUTES.MAILINGLIST}`}
        element={<PublicRoutes layout={AULayout} component={Competitions} />}
      />

      {/* SohoLounge */}
      <Route
        path={`${ROUTES.SOHOLOUNGE}`}
        element={<PublicRoutes layout={AULayout} component={SohoLounge} />}
      />

      {/* gaming hire */}
      <Route
        path={`${ROUTES.GAMING_HIRE}`}
        element={<PublicRoutes layout={AULayout} component={GamingHires} />}
      />

      {/* Gift Shop */}
      <Route
        path={ROUTES.GIFTSHOP}
        element={<PublicRoutes layout={AULayout} component={GiftShop} />}
      >
        <Route path={ROUTES.ECARDS} element={<GiftCards />} />
        <Route path={ROUTES.PHYSICAL_CARDS} element={<GiftCards />} />
        <Route path={ROUTES.EXPERIENCE} element={<GiftCards />} />
      </Route>

      <Route
        path={`/${ROUTES.GIFTSHOP}/${ROUTES.GIFT_CART}`}
        element={<PublicRoutes layout={AULayout} component={GiftCart} />}
      />

      {/* Side Menus */}
      <Route
        path={ROUTES.OFFERS_EVENTS}
        element={<PublicRoutes layout={AULayout} component={Deals} />}
      />
      <Route
        path={ROUTES.FAQ}
        element={<PublicRoutes layout={AULayout} component={Faq} />}
      />
      <Route
        path={ROUTES.CINEMA_HIRE}
        element={<PublicRoutes layout={AULayout} component={CinemaHire} />}
      />

      {/* account Verification */}
      <Route
        path={ROUTES.CONFIRM_USER}
        element={<PublicRoutes layout={AULayout} component={Movies} />}
      >
        <Route path={`:code`} element={<ConfirmUser />} />
      </Route>

      <Route path={ROUTES.PRIVACY_POLICY_AU} element={<PrivacyPolicy />}>
        {/* <Route path={":type"} element={null} /> */}
      </Route>
      <Route path={ROUTES.CONTACT_US_AU} element={<ContactUs />}>
        {/* <Route path={":type"} element={null} /> */}
      </Route>
      <Route
        path={`${ROUTES.RESET_PASSWORD}`}
        element={<PublicRoutes layout={AULayout} component={Movies} />}
      >
        <Route path={`:code`} element={<ResetPassword />} />
      </Route>
      {/* Wild card */}
      <Route
        path="*"
        element={<Navigate to={`${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`} />}
      />
    </Routes>
  ) : brandId === BRANDS.US ? (
    <Routes>
      {/* Movie Pages */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.MOVIES}`}
        element={<PublicRoutes layout={USLayout} component={Home} />}
      />
      <Route
        path={`${ROUTES.HOME}/${ROUTES.NOW_PLAYING}`}
        element={<PublicRoutes layout={USLayout} component={Home} />}
      />
      <Route
        path={`${ROUTES.HOME}/${ROUTES.COMING_SOON}`}
        element={<PublicRoutes layout={USLayout} component={Home} />}
      />
      <Route
        path={`${ROUTES.HOME}/${ROUTES.ADVANCE_TICKETS}`}
        element={<PublicRoutes layout={USLayout} component={Home} />}
      />
      <Route
        path={ROUTES.HOME}
        element={<PublicRoutes layout={USLayout} component={Home} />}
      >
        {/* Food and beverage */}
        <Route path={ROUTES.EAT_AND_DRINK} element={<FoodAndBeverage />} />
        <Route path={ROUTES.FOOD_IAM_HERE} element={<FoodCollect/>} />
        {/* Ticketing */}
        <Route path={ROUTES.TICKETS} element={<Ticketing type="ticket" />} />
        {/* Refund */}
        <Route path={ROUTES.REFUND} element={<Ticketing type="refund" />} />
        {/* Refund */}
        <Route
          path={ROUTES.SWAP_SEAT}
          element={<Ticketing type="swapSeat" />}
        />
        {/* Soical Share */}
        <Route
          path={ROUTES.SOCIAL_SHARE}
          element={<Ticketing type="ticket" />}
        />
        {/* Contact Us */}
        <Route path={ROUTES.CONTACT_US} element={<ContactUs />}>
          <Route path={":type"} element={null} />
        </Route>
        {/* Privacy policy */}
        <Route path={ROUTES.PRIVACY} element={<PrivacyPolicy />}>
          <Route path={":type"} element={null} />
        </Route>
        {/* Terms */}
        <Route path={ROUTES.TERMS} element={<Terms />} />
        {/* GiftCardTerms */}
        <Route path={ROUTES.GIFT_CARD_TERMS} element={<GiftCardTerms />} />
        {/* EtixTerms */}
        <Route path={ROUTES.ETIX_TERMS} element={<EtixTerms />} />
        {/* CinemaExtras */}
        <Route path={ROUTES.CINEMA_EXTRAS} element={<CinemaExtras />} />
        {/* Update profile */}
         <Route path={ROUTES.UPDATE_PROFILE} element={<UpdateProfile />} />
        {/* Rating */}
        <Route path={ROUTES.RATING} element={<RatingIndex />} />
        {/* Group booking */}
        <Route path={ROUTES.GROUP_SALE} element={<GroupSale />} />
        {/* Cinema Hire */}
        <Route path={ROUTES.CINEMA_HIRE} element={<CinemaHire />} />
        <Route path={ROUTES.REGISTRATION} element={<Registration />} />
      </Route>

      {/* Movie details */}
      <Route
        path={ROUTES.MOVIE_DETAILS_SLUG}
        element={<PublicRoutes layout={USLayout} component={UsMovieDetails} />}
      />
      {/* Signature Programming */}
      <Route
        path={ROUTES.SIGNATURE_PROGRAMS}
        element={
          <PublicRoutes layout={USLayout} component={SignaturePrograming} />
        }
      />
      {/* Signature Programming */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.SIGNATURE_PROGRAM}`}
        element={
          <PublicRoutes layout={USLayout} component={SignaturePrograming} />
        }
      />

      {/* News letter */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.NEWS_LETTER}`}
        element={<PublicRoutes layout={USLayout} component={NewsLetter} />}
      />

      {/* Titan luxe */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.MOVIES}/${ROUTES.TITAN_FLAG}`}
        element={<PublicRoutes layout={USLayout} component={TitanMovies} />}
      />

      {/* Special Value Program*/}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.SPECIAL_VALUES}`}
        element={
          <PublicRoutes layout={USLayout} component={SpecialValueProgram} />
        }
      />
      <Route
        path={`${ROUTES.HOME}/${ROUTES.SPECIAL_VALUE}`}
        element={
          <PublicRoutes layout={USLayout} component={SpecialValueProgram} />
        }
      />

      {/* Rewards */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.REWARDS}`}
        element={<PublicRoutes layout={USLayout} component={Rewards} />}
      />

      {/* Search */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.SEARCH}`}
        element={<PublicRoutes layout={USLayout} component={SearchUS} />}
      />

      {/* Advertise with us */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.ADVERTISE}`}
        element={<PublicRoutes layout={USLayout} component={AdvertiseWithUs} />}
      />

      {/* Accessibility */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.ACCESSIBILITY}`}
        element={<PublicRoutes layout={USLayout} component={Accessibility} />}
      />

      {/* Careers */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.CAREER}`}
        element={<PublicRoutes layout={USLayout} component={Careers} />}
      />
      <Route
        path={`${ROUTES.HOME}/${ROUTES.CAREERS}`}
        element={<PublicRoutes layout={USLayout} component={Careers} />}
      />

      {/* FAQ */}
      <Route
        path={ROUTES.FAQ_US}
        element={<PublicRoutes layout={USLayout} component={Faq} />}
      />

      {/* Cinema Hire */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.VIP_EXPERIENCE}`}
        element={<PublicRoutes layout={USLayout} component={VipCinemas} />}
      />

      {/* Gift Shop */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.GIFTSHOP}`}
        element={<PublicRoutes layout={USLayout} component={GiftShopUs} />}
      ></Route>

      {/* Gift Cart */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.GIFTSHOP}/${ROUTES.GIFT_CART}`}
        element={<PublicRoutes layout={USLayout} component={GiftCart} />}
      />

      <Route
        path={`${ROUTES.RESET_PASSWORD}`}
        element={<PublicRoutes layout={USLayout} component={Home} />}
      >
        <Route path={`:code`} element={<ResetPassword />} />
      </Route>

      <Route
        path={`${ROUTES.CONFIRM_USER}`}
        element={<PublicRoutes layout={USLayout} component={Home} />}
      >
        <Route path={`:code`} element={<ConfirmUser />} />
      </Route>

        {/* Custom Page*/}
        <Route
        path={`${ROUTES.HOME}/${ROUTES.CUSTOM_PAGE}`}
        element={<PublicRoutes layout={USLayout} component={CustomPage} />}
      />

      {/* Mmeber Optin*/}
      <Route
        path={`${ROUTES.MEMBERSHIP_SIGN_UP}`}
        element={<PublicRoutes layout={USLayout} component={MembershipSignin} />}
      />

      {/* Profile menu Routes */}
        <Route
          path={`${ROUTES.HOME}/${ROUTES.PROFILE_TICKETS}`}
          element={<PublicRoutes layout={USLayout} component={MyTickets} />}
        />
        <Route
          path={`${ROUTES.HOME}/${ROUTES.PROFILE_REWARDS}`}
          element={<PublicRoutes layout={USLayout} component={MyRewards} />}
        />
        <Route
          path={`${ROUTES.HOME}/${ROUTES.PROFILE_PAYMENT_METHODS}`}
          element={<PublicRoutes layout={USLayout} component={MyPaymentMethods} />}
        />
        <Route
          path={`${ROUTES.HOME}/${ROUTES.PROFILE_GIFT_CARDS}`}
          element={<PublicRoutes layout={USLayout} component={MyGiftCards} />}
        />
        <Route
          path={`${ROUTES.HOME}/${ROUTES.PROFILE_UPDATE}`}
          element={<PublicRoutes layout={USLayout} component={MyProfileUpdate} />}
        />

      {/* Wild card */}
      <Route
        path="*"
        element={<PublicRoutes layout={USLayout} component={Home} />}
      />
    


      {/* Page Not Found */}
      <Route
        path={`${ROUTES.HOME}/${ROUTES.PAGE_NOT_FOUND}`}
        element={<PublicRoutes layout={USLayout} component={PageNotFound} />}
      />
    </Routes>
  ) : (
    <Routes></Routes>
  );
};

export default AppRoutes;
