import React, { FC, useEffect, useMemo, useState } from "react";
import Barcode from "react-barcode";
import "./refund.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { useNavigate, useParams } from "react-router";
import {
  checkRefundStatusApi,
  refundPayment,
} from "../../../../services/payment";
import { toast } from "react-toastify";
import { ICheckRefund, IConcessionData, IConcessionFormatData, ITicketData, ITicketFormatData } from "../../../../models/payment";
import { getPaymentMethod } from "../../../../services/payment";
import { TICKET_FLOW } from "../../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../models/tickets";
import { setModal } from "../../../../store/ticketBooking/action";
import { BRANDS } from "../../../../constants/app";
import { IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";
import MovieDescription from "../../../../components/TicketBooking/MovieDescription";
import { formatRefundConcession, formatRefundTicket } from "../../../../utils/helper";
import Button from "../../../../components/UI/Button";

interface IRefundTicketProps {
  onCloseModal: () => void;
}

const Refund: FC<IRefundTicketProps> = ({ onCloseModal }) => {
  const { countryId, sharedUserSessionId, brandId, currentCinema, ticketLessFb } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      currentCinema: state.applicationReducer.currentCinema,
      sharedUserSessionId: state.ticketBookingReducer.modal.data,
      brandId: state.applicationReducer.brandId,
      ticketLessFb: state.foodBeverageReducer.ticketLessFb,
    }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refundData, setRefundData] = useState<ICheckRefund | null>(null);
  const [refundItems, setRefundItems] = useState<any>({
    ticketInput: [],
    concessionInput: []
  });

  const checkRefund = async (sharedUserSessionId: string) => {
    const {
      data: { data },
    } = await checkRefundStatusApi({
      saleId: sharedUserSessionId,
      countryId: countryId,
    });
    if (data && data.status) {
      setRefundData(data);
    } else {
      toast.error(data.message, { autoClose: 8000 });
      navigate(`/${currentCinema?.alias}`);
      onCloseModal();
    }
  };

  useEffect(() => {
    if (countryId && sharedUserSessionId) {
      checkRefund(sharedUserSessionId);
    }
  }, [countryId, sharedUserSessionId]);

  const totalRefundAmount = useMemo(() => {
    return (refundItems.ticketInput.reduce((acc: number, v: ITicketData) => {
      acc = acc + v.Ticket_price
      return acc;
    }, 0)) + (refundItems.concessionInput.reduce((acc: number, v: IConcessionData) => {
      acc = acc + v.Price
      return acc;
    }, 0))
  }, [refundItems])

  const entireOrderAmount = useMemo(() => {
    let ticket = 0;
    let concession = 0;
    if (refundData?.ticketData) {
      ticket = (refundData?.ticketData?.reduce((acc: number, v: ITicketData) => {
        acc = v.refundstatus === null?(acc + v.Ticket_price):acc
        return acc;
      }, 0))
    }
    if (refundData?.concessionData) {
      concession = (refundData?.concessionData.reduce((acc: number, v: IConcessionData) => {
        acc = v.refundstatus === null?(acc + v.Price):acc
        return acc;
      }, 0))
    }
    return ticket + concession;
  }, [refundData])

  const allChecked = useMemo(()=>{
    return totalRefundAmount === entireOrderAmount && entireOrderAmount > 0
  },[totalRefundAmount,entireOrderAmount])

  const processRefund = async () => {
    if (totalRefundAmount <= 0) {
      toast.error('Please select the item to refund')
      return;
    }
    const refundDetails: any = {
      amount: parseInt(
        (
          (totalRefundAmount) *
          100
        ).toFixed()
      ),
      countryId: countryId,
      txnType: 4,
      requestType: "refundPayment",
      paymentMethod: getPaymentMethod(countryId),
      txnID: refundData?.txnId,
      transactionReference: refundData?.transactionRefNo,
      saleId: sharedUserSessionId,
      refundType: "individual",
      ticketInput: refundItems.ticketInput,
      concessionInput: refundItems.concessionInput
    };

    const { status, data } = (await refundPayment(refundDetails)) as any;
    if (
      status &&
      data &&
      data.data &&
      (data.data.message === "Approved" || data.data.message === "Refund initiated")
    ) {
      checkRefund(sharedUserSessionId);
      toast.success("Refund has been successfully initiated");
      setRefundItems({
        ticketInput: [],
        concessionInput: []
      })
      // const modal =
      //   TICKET_FLOW[TICKET_FLOW_TYPES.REFUND][MODAL_TYPE.REFUND_CONFIRMATION];
      // dispatch(
      //   setModal({
      //     ...modal,
      //     type: MODAL_TYPE.REFUND,
      //     data: refundData?.email,
      //   })
      // );
    } else {
      toast.error( "Error occured please try later");
      // onCloseModal();
    }
  };

  const onClickItem = (event: any, v: any, type: string) => {
   const value = type === 'ticketInput'?formatRefundTicket(v):formatRefundConcession(v)
    const { target: { checked } } = event;
    const items = { ...refundItems };
    v.checked = checked;
    if (checked) {
      items[type] = [...refundItems[type], value];
      setRefundItems(items);
    } else {
      const index = refundItems[type].findIndex((v: any) => v.RowId === value.RowId);
      if (index > -1) {
        items[type].splice(index, 1);
        setRefundItems(items);
      }
    }
  }

  const onSelectAll = (event: any)=>{
    const { target: {checked}} = event;
    if(checked){
      let ticket:ITicketFormatData[] = [];
      let concession:IConcessionFormatData[] = [];
      if (refundData?.ticketData) {
        ticket = refundData?.ticketData?.filter((v:ITicketData)=>v.refundstatus === null).map((v: ITicketData) => {
          v.checked = checked;
          return formatRefundTicket(v);
        })
      }
      if (refundData?.concessionData) {
        concession = refundData?.concessionData.filter((v:IConcessionData)=> v.refundstatus === null).map((v: IConcessionData) => {
          v.checked = checked;
          return formatRefundConcession(v) 
        })
      }
      setRefundItems({
        ticketInput: ticket,
        concessionInput: concession
      })
    }else{
      refundData?.ticketData?.forEach((v: ITicketData) => {
        v.checked = false;
      })
      refundData?.concessionData?.forEach((v: IConcessionData) => {
        v.checked = false;
      })

      setRefundItems( {
        ticketInput: [],
        concessionInput: []
      })
    }
  }

  return (
    <div className="row refund-wrapper">
      {/* {!ticketLessFb ? <MovieDescription enableShare={false} /> : null} */}
      <div className={`${ticketLessFb ? "col-12  px-2" : "col-md-12 px-2"}`}>
        <div className="refund-wrapper">
          <div className={`col-12`}>
            <div className="refund-header">
              Are you sure you want to refund your order?
            </div>
            <div className="refund-subheader">
              You have up to 2 hours prior to your session start time to refund your
              tickets.
              <br />
              Alternatively, you have up to 30 minutes prior to your session to
              {" "}<span className="ticket-highlight" >Change Seats.</span>
              <br />
              <br />
              Please note: Service fees are non-refundable.
            </div>
          </div>
        </div>
        <div>
          <div className="">
            <div className="ticket_detail_wrap">
              <div className="ticket_detail">
                <div className="ticket_number">
                  <p className="label">ORDER NUMBER</p>
                  <p className="number">
                    {refundData?.ticketConfirmationNumber}
                  </p>
                </div>
              </div>

              <div className="bar_code">
                {brandId === BRANDS.US &&
                  refundData?.ticketConfirmationNumber ? (
                  <img
                    style={{ width: 150, height: 150 }}
                    src={`${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.QR_PATH}/${refundData?.ticketConfirmationNumber!}.png`}
                    alt=""
                    width="100"
                  />
                ) : (
                  <Barcode
                    width={2}
                    height={80}
                    displayValue={false}
                    value={refundData?.ticketConfirmationNumber!}
                  />
                )}
              </div>
            </div>
            <p className="refund-title">You can refund your entire order or individual items.</p>
            <div className="items-details">
              <div className="custom_checkbox">
                <input type="checkbox" disabled={entireOrderAmount<=0} checked={allChecked} onClick={onSelectAll} />
                <div className="state p-primary">
                  <label className="form-label refund-label">
                    <div className="title">Refund Entire Order</div>
                    <div className="desc">Refund Total: ${Number(entireOrderAmount).toFixed(2)}</div>
                  </label>
                </div>
              </div>
            </div>
            <table className="movie_details">
              <tbody>
                {refundData?.ticketData?.length ? <tr>
                  <td colSpan={2}>
                    <table className="items-details">
                      <tr>
                        <th>Ticket Type</th>
                        <th>Seats</th>
                      </tr>
                      {refundData?.ticketData.map(v => <tr>
                        <td className={`refund-items ${v.refundstatus === 'refund' ? 'disabled' : ''}`}>
                          <div className="custom_checkbox">
                            <input type="checkbox" checked={v.checked} disabled={v.refundstatus === 'refund'} onClick={(event) => !v.refundstatus && onClickItem(event, v, 'ticketInput')} />
                            <div className="state p-primary">
                              <label className="form-label refund-label">
                                <div className="title">{v.Ticket_type}</div>
                                <div className="desc">${v.Ticket_price.toFixed(2)}</div>
                              </label>
                            </div>
                          </div>
                        </td>
                        <td>
                          {v.package_seat?v.package_seat:v.Seat_number}
                        </td>
                      </tr>)}
                    </table>
                  </td>
                </tr> : null}
                {refundData?.concessionData?.length ? <tr>
                  <td colSpan={2}>
                    <table className="items-details">
                      <tr>
                        <th>Food & Beverage</th>
                      </tr>
                      {refundData?.concessionData.map(v => <tr>
                        <td className={`refund-items ${v.refundstatus === 'refund' ? 'disabled' : ''}`}>
                          <div className="custom_checkbox">
                            <input type="checkbox" checked={v.checked} disabled={v.refundstatus === 'refund'} onClick={(event) => !v.refundstatus && onClickItem(event, v, 'concessionInput')} />
                            <div className="state p-primary">
                              <label className="form-label refund-label">
                                <div className="title">{v.Description}</div>
                                <div className="desc">${v.Price.toFixed(2)}</div></label>
                            </div>
                          </div>
                        </td>
                      </tr>)}
                    </table>
                  </td>
                </tr> : null}
                <tr>
                  <th>Total Refund Amount</th>
                  <td style={{ color: "#D0021B" }}>
                    $
                    {(totalRefundAmount).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="notes">
              Your total will be refunded to the original form of payment.{" "}
              <br />
              Please allow up to 72 business hours for the funds to reflect in
              your account.{" "}
            </div>
          </div>
          <div className={`movie_footer_btn_el col-md-12 ml-auto`}>
            <div className="movie_button_wrap justify-content-end">
              <Button classNames="m-r-10" onClick={onCloseModal} text="Cancel" type="outline" />
              <Button iconWidth={10} iconHeight={20} disabled={entireOrderAmount<=0} onClick={processRefund} text= "Process Refund" type="fill" iconPosition = "post" icon={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}arrow_right_white.svg`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refund;
