import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../store";
import "./header.scss";
import {
  getCinemaWatcher,
  openSliderAction,
  setCinemaAction,
} from "../../../store/application/action";
import { findCinemaObject, formatLocation } from "../../../utils/formatter";
import QuickTickets from "../../../components/UI/Filters/QuickTickets";
import LocationDropDown from "../../../components/UI/DropDown/Location";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/path";
import {
  logoutAction,
  showLogin,
  showUpdateProfileModal,
} from "../../../store/auth/action";
import { logOutApi } from "../../../services/auth";
import { sleep } from "../../../utils/helper";
import UpdateProfile from "../../../components/Menu/AuthMenu/UpdateProfile";
import { IMainNav } from "../../../models/sidemenu";
import Popover from "../../../components/UI/Popover";
import { COUNTRY_IMAGE_CONFIG, IMAGE_URL_CONFIG, URLS } from "../../../constants/url";
import { searchMovie, setNowShowingLoader, setQuickFilter } from "../../../store/films/action";
import { ALLOWED_MEMBER_VALIDATE, COUNTRY } from "../../../constants/app";
import MD5 from 'crypto-js/md5';
import { MODAL_TYPE } from "../../../models/tickets";

interface IHeaderProps { }

type OpenMenusState = {
  [key: string]: boolean;
};

const PROFILE_MEMU = [
  { label: "My Membership", link: ROUTES.PROFILE_REWARDS, img: "menu-barcode.svg", altText: "membership image", width: 40, height: 30 },
  { label: "My Tickets", link: ROUTES.PROFILE_TICKETS, img: "menu-ticket.svg", altText: "tickets image", width: 30, height: 26 },
  { label: "My Rewards", link: ROUTES.PROFILE_REWARDS, img: "menu-rewards.svg", altText: "rewards image", width: 23, height: 30 },
  { label: "My Payment Method", link: ROUTES.PROFILE_PAYMENT_METHODS, img: "menu-payment.svg", altText: "Payment image", width: 33, height: 26 },
  { label: "My Gift Cards", link: ROUTES.PROFILE_GIFT_CARDS, img: "menu-gift.svg", altText: "Gift card image", width: 37, height: 27 },
  { label: "My Profile", link: ROUTES.PROFILE_UPDATE, img: "menu-user-icon-blue.svg", altText: "Profile image", width: 30, height: 20 }
]

const Header: FC<IHeaderProps> = () => {
  const {
    menu,
    countryId,
    cinemas,
    currentCinema,
    isLoggedIn,
    quickFilters,
    brandId,
    userDetails,
    memberDetail,
    ticketModal,
    openSlider
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    menu: state.applicationReducer.menu,
    brandId: state.applicationReducer.brandId,
    cinemas: state.applicationReducer.cinemas,
    currentCinema: state.applicationReducer.currentCinema,
    isLoggedIn: state.authReducer.isLoggedIn,
    quickFilters: state.filmsReducer.quickFilters,
    userDetails: state.authReducer.userDetails,
    memberDetail: state.authReducer.memberDetail,
    ticketModal: state.ticketBookingReducer.openModal,
    openSlider: state.applicationReducer.openSlider
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tooltipRef = useRef<any>();
  const tooltipMobileRef = useRef<any>();
  const searchRef = useRef<any>(null);
  const { pathname } = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [cinemaList, setCinemaList] = useState([]);
  const [showQuickTickets, setShowQuickTickets] = useState<boolean>(false);
  const [filterClass, setFilterClass] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [siteName, setSiteName] = useState("");
  const [siteLogo, setSiteLogo] = useState("");
  const [closeMenu, setCloseMenu] = useState(true);
  const [hoverIcons, setHoverIcons] = useState({
    facebook: "",
    instagram: "",
    twitter: "",
    tiktok: "",
    youtube: "",
    mail: "",
    signin: "",
  });

  const [openMenus, setOpenMenus] = useState<OpenMenusState>({});
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [isAlreadyOpened, setIsAlreadyOpened] = useState(false);


  useEffect(() => {
    if (countryId) {
      dispatch(getCinemaWatcher({ countryId }));
      if (countryId === COUNTRY.US) {
        setSiteName("READING CINEMAS");
        setSiteLogo("reading_logo_us.svg");
        setHoverIcons({
          facebook: "facebook_red.svg",
          instagram: "Instagram_red.svg",
          twitter: "twitter-logo-new-red.svg",
          tiktok: "tiktok_red.svg",
          youtube: "youtube_red_1.svg",
          mail: "mail_red.svg",
          signin: "signin_red.svg",
        });
      } else if (countryId === COUNTRY.AFC) {
        setSiteName("ANGELIKA FILM CENTER");
        setSiteLogo("afc/angelika_logo.svg");
        setHoverIcons({
          facebook: "afc/facebook_red.svg",
          instagram: "afc/Instagram_red.svg",
          twitter: "afc/twitter-logo-new-red.svg",
          tiktok: "afc/tiktok_red.svg",
          youtube: "afc/youtube_red_1.svg",
          mail: "afc/mail_red.svg",
          signin: "afc/signin_red.svg",
        });
      }
      if (countryId === COUNTRY.CON) {
        setSiteName("CONSOLIDATED THEATRES");
        setSiteLogo("con/consolidated_logo.svg");
        setHoverIcons({
          facebook: "con/facebook_red.svg",
          instagram: "con/Instagram_red.svg",
          twitter: "con/twitter-logo-new-red.svg",
          tiktok: "con/tiktok_red.svg",
          youtube: "con/youtube_red_1.svg",
          mail: "con/mail_red.svg",
          signin: "con/signin_red.svg",
        });
      }
    }
  }, [countryId]);

  useEffect(() => {
    setShowQuickTickets(false);
  }, [quickFilters]);

  useEffect(() => {
    if (countryId && cinemas && cinemaList.length === 0) {
      setCinemaList(formatLocation(cinemas, true));
    }
  }, [countryId, cinemas]);

  const setTooltip = (ref: any) => {
    const hidetooltip = localStorage.getItem("hidetooltip");
    if (hidetooltip) {
      const data = JSON.parse(hidetooltip);
      if (data && !data[MD5(userDetails?.email!).toString()]) {
        ref.current.style.visibility = 'visible'
        ref.current.style.opacity = 1;
        return;
      }
    }else{
      ref.current.style.visibility = 'visible'
      ref.current.style.opacity = 1;
      return;
    }
    ref.current.style.visibility = 'hidden'
    ref.current.style.opacity = 0
  }

  useEffect(() => {
    if (tooltipRef?.current) {
      setTooltip(tooltipRef)
    }
    if (tooltipMobileRef?.current) {
      setTooltip(tooltipMobileRef)
    }
  }, [tooltipRef?.current, tooltipMobileRef?.current])

  const points = useMemo(() => {
    return memberDetail &&
      memberDetail.LoyaltyMember &&
      memberDetail.LoyaltyMember.BalanceList &&
      memberDetail.LoyaltyMember.BalanceList.length > 0 &&
      memberDetail.LoyaltyMember.BalanceList[0].PointsRemaining
      ? memberDetail.LoyaltyMember.BalanceList[0].PointsRemaining
      : -1;
  }, [memberDetail]);

  const onChangeCinema = (value: string) => {
    if (cinemas) {
      const curcinema = findCinemaObject(cinemas, value);
      dispatch(setCinemaAction(curcinema));
      navigate(`/${curcinema && curcinema.alias ? `${curcinema.alias}` : ""}`);
      setOpenModal(false);
      dispatch(setNowShowingLoader(true));
      dispatch(
        setQuickFilter({
          ...quickFilters,
          format: null,
          location: curcinema?.slug,
          movie: null,
          session: null,
          date: null,
        })
      );
    }
  };

  useEffect(() => {
    if (ticketModal || pathname) {
      setShowQuickTickets(false);
    }
  }, [ticketModal, pathname]);

  const onShowQuickTickets = async () => {
    setFilterClass(!showQuickTickets);
    if (showQuickTickets) {
      await sleep(500);
    }
    setShowQuickTickets(!showQuickTickets);
  };

  const onSignIn = () => {
    modifyPopover(false);
    if (isLoggedIn) {
      dispatch(showLogin(false));
      dispatch(openSliderAction({ open: true, type: "account" }));
    } else {
      dispatch(showLogin(true));
    }
  };

  const logout = async () => {
    dispatch(showLogin(false));
    const { status } = await logOutApi();
    if (status === 200) {
      dispatch(logoutAction({}));
      navigate(`/${currentCinema?.alias}/${ROUTES.NOW_PLAYING}`);
    }
  };

  const onClickEdit = () => {
    // if(currentCinema?.slug && ALLOWED_MEMBER_VALIDATE.includes(currentCinema?.slug)){
    dispatch(openSliderAction({ open: true, type: "account" }));
    closeTooltip(tooltipMobileRef)
    closeTooltip(tooltipRef);
    // }else{
    //   dispatch(showUpdateProfileModal(true));
    // }
  };

  const onSearch = (event: any) => {
    if (event.key === "Enter") {
      const {
        target: { value },
      } = event;
      if (value.trim()) {
        dispatch(searchMovie(value.trim()));
        navigate(`/${currentCinema?.alias}/${ROUTES.SEARCH}`);
        setShowSearch(false);
      }
    }
  };

  const onClickSearch = () => {
    setShowSearch(!showSearch);
    if (searchRef && searchRef?.current) {
      searchRef?.current?.focus();
      searchRef.current.value = "";
    }
  };

  const onCloseMenu = () => {
    setCloseMenu(!closeMenu);
  };

  const onCloseMenuValue = () => {
    setCloseMenu(false);
  };

  const handleTrigger = (item: string) => {
    setOpenMenus(prevState => {
      const isItemExists = prevState.hasOwnProperty(item);
      return {
        ...prevState,
        [item]: isItemExists ? !prevState[item] : true
      };
    });
  };

  const closeTooltip = (ref:any) => {
    if (ref.current) {
      const data = localStorage.getItem("hidetooltip")
      const tootipUser: any = data && typeof JSON.parse(data) === "object"? JSON.parse(data) : {};
      ref.current.style.visibility = 'hidden'
      ref.current.style.opacity = 0;
      tootipUser[MD5(userDetails?.email!).toString()] = true;
      localStorage.setItem("hidetooltip", JSON.stringify(tootipUser));
    }
  }

  const showPoints = useMemo(() => {
    return ALLOWED_MEMBER_VALIDATE.includes(currentCinema?.slug)
  }, [currentCinema])

  useEffect(() => {
    if (currentCinema && countryId === COUNTRY.AFC && !isAlreadyOpened) {
      setIsAlreadyOpened(!isLoggedIn)
      setPopoverVisible(!isLoggedIn)
    } else if (!isLoggedIn) {
      setPopoverVisible(false)
    }
  }, [currentCinema, isLoggedIn])

  const modifyPopover = (newStatus: boolean) => {
    setPopoverVisible(newStatus);
    closeTooltip(tooltipRef);
    closeTooltip(tooltipMobileRef);
  };

  return (
    <>
      <div className="header-us d-md-none">
        <div className={`header-mobile ${countryId === COUNTRY.CON
          ? "consolidated"
          : countryId === COUNTRY.AFC
            ? "angelika-flim-center"
            : ""
          }`}>
          <div className="header-top-section">
            {countryId === COUNTRY.AFC ? <a role="button" className="btn-rewards" target="_blank" href={URLS.ANGELIKA_FILM_MEMBERSHIP}>rewards</a> : null}
            <div className="wrapper" onClick={() => setShowQuickTickets(false)}>
              <div className="sign-in">
                {isLoggedIn ?
                  <span className=" highlightbox" onClick={(e) => (countryId === COUNTRY.AFC && popoverVisible === false) ? modifyPopover(true) : null}>
                    <img className="mx-1"
                      src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}signin_icon.svg`}
                      alt="signin image"
                    />
                    {userDetails?.firstName}
                    <span className="tooltiptext" ref={tooltipMobileRef}>
                      <h4 className="heading">Welcome back!</h4>
                      <p className="title">Please use <span className="view-profile">VIEW PROFILE</span> to access below information:</p>
                      <div className="desc">
                        <ul>
                          {countryId === COUNTRY.AFC?<li>Angelika Membership Rewards and ID Number</li>:null}
                          <li>Upcoming Movie Tickets and Purchase History</li>
                          <li>Manage Gift Cards & E-Gift Cards</li>
                          <li>Update payment information</li>
                        </ul>
                      </div>
                      <div>
                        <button className="btn-got-it" onClick={()=>closeTooltip(tooltipMobileRef)}>Got it!</button>
                      </div>
                    </span>
                  </span>
                  : (<>
                    <span
                      onClick={() => (countryId === COUNTRY.AFC && popoverVisible === false) ? modifyPopover(true) : onSignIn()}
                      onMouseOver={(e) => (countryId === COUNTRY.AFC && popoverVisible === false) ? modifyPopover(true) : null}
                      className="mx-2">
                      Sign in
                    </span>
                    <img
                      src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}signin_icon.svg`}
                      alt="signin image"
                    /></>
                  )}

              </div>
              {countryId === COUNTRY.AFC ? (
                <Popover visible={popoverVisible} logged={isLoggedIn}>
                  {isLoggedIn ? (
                    <>
                      <div className="logged-profile" onMouseLeave={() => modifyPopover(false)}>
                        <div className="header">
                          <div className="user-info">
                            <span className="user-icon">
                              <img className="mx-1"
                                src={`${COUNTRY_IMAGE_CONFIG[COUNTRY.AFC]}menu-user-icon-pink.svg`}
                                alt="signin image"
                              />
                            </span>
                            <span className="menu-user-name ">
                              {userDetails?.firstName} {Number(points) >= 0 && showPoints ? (
                                <>(<span className="points-label">{points}</span> Pts)</>
                              ) : null}
                            </span>
                          </div>
                        </div>
                        {PROFILE_MEMU.map(v => <div className="nav-item">
                          <span className="nav-icon">
                            <img className="mx-1"
                              src={`${COUNTRY_IMAGE_CONFIG[COUNTRY.AFC]}${v.img}`}
                              alt={v.altText}
                              width={v?.width}
                              height={v?.height}
                            />
                          </span>
                          <span className="nav-text" onClick={() => modifyPopover(false)}>
                            <Link to={`/${currentCinema?.alias}/${v.link}`} target="_self">{v.label}</Link></span>
                        </div>)}
                        <div className="nav-item" onClick={logout}>
                          <span className="nav-icon">
                            <img className="mx-1"
                              src={`${COUNTRY_IMAGE_CONFIG[COUNTRY.AFC]}menu-logout.svg`}
                              alt="LOG OUT image"
                            />
                          </span>
                          <span className="nav-text">LOG OUT</span>
                        </div>
                        <button className="close-button close_icon" aria-label="close pre-sign in popover"
                          onClick={() => modifyPopover(false)}></button>
                      </div>
                    </>
                  ) : (
                    <><h4 className="heading"><b>Angelika Rewards</b> are waiting.</h4>
                      <div className="desc">
                        <ul>
                          <li>
                            <img src={COUNTRY_IMAGE_CONFIG[countryId] + "checkmark.svg"} role="background" aria-label="background-image-checkmark"></img>
                            <span>Earn 1 Point for Every $1 you spend</span>
                          </li>
                          <li>
                            <img src={COUNTRY_IMAGE_CONFIG[countryId] + "checkmark.svg"} role="background" aria-label="background-image-checkmark" ></img>
                            <span>Points = Earn $$$ Rewards</span>
                          </li>
                          <li>
                            <img src={COUNTRY_IMAGE_CONFIG[countryId] + "checkmark.svg"} role="background" aria-label="background-image-checkmark" ></img>
                            <span>10% off Food & Drink and more!</span>
                          </li>
                        </ul>
                      </div>
                      <button className="close-button close_icon" aria-label="close pre-sign in popover" onClick={() => modifyPopover(false)}></button>
                      <div className="popover-btn-container">
                        <button className="popover-signin" aria-label="open sign in popup" onClick={onSignIn}>Sign in or Join for Free</button>
                      </div>
                    </>
                  )}
                </Popover>
              ) : null}
              <div>
                <ul className="social-media">
                  {currentCinema?.facebook_url ? (
                    <li>
                      <a href={currentCinema?.facebook_url} target="_blank" title="Click here to go to our Facebook page" aria-label="Click here to go to our Facebook page">
                        <img
                          src={
                            IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                            "facebook_icon.svg"
                          }
                          alt="facebook image"
                        />
                      </a>
                    </li>
                  ) : null}
                  {currentCinema?.instagramUrl ? (
                    <li>
                      <a href={currentCinema?.instagramUrl} target="_blank" title="Click here to go to our Instagram page" aria-label="Click here to go to our Instagram page">
                        <img
                          src={
                            IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                            "instagram_icon.svg"
                          }
                          alt="instagram image"
                        />
                      </a>
                    </li>
                  ) : null}
                  {currentCinema?.twitter_url ? (
                    <li>
                      <a href={currentCinema?.twitter_url} target="_blank" title="Click here to go to our Twitter page" aria-label="Click here to go to our Twitter page">
                        <img
                          style={{ width: 12, margin: "0 8px" }}
                          src={
                            IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                            "twitter-logo-new.svg"
                          }
                          alt="twitter image"
                        />
                      </a>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
          <div className="logo-location">
            <div className="logo-wrapper">
              <div
                className="logo"
                onClick={() => navigate(`/${currentCinema?.alias}`)}
              >
                <img
                  src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + siteLogo}
                  alt="logo image"
                />
              </div>
              <div>
                <div className="site-name">{siteName}</div>
                <div>
                  {currentCinema ? (
                    <div>
                      <LocationDropDown
                        value={{
                          value: currentCinema ? currentCinema.slug : "",
                          label: currentCinema
                            ? countryId === COUNTRY.CON
                              ? currentCinema.displayName.toUpperCase()
                              : currentCinema.name.toUpperCase()
                            : "",
                        }}
                        options={cinemaList}
                        placeholder="Select Cinema"
                        onChange={onChangeCinema}
                        className={
                          countryId === COUNTRY.CON
                            ? "location-dropdown-con"
                            : countryId === COUNTRY.AFC
                              ? "location-dropdown-afc"
                              : "location-dropdown-us"
                        }
                        classNamePrefix="location-us"
                        countryId={countryId}
                        brandId={brandId}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="navigation">
              <div className="menuToggle">
                <input type="checkbox" checked={!closeMenu} aria-label="close menu" onChange={onCloseMenu} />
                <span></span>
                <span></span>
                <span></span>

                <ul className={`menu ${closeMenu ? "closeMenu" : ""}`}>
                  {menu.header.map((v: IMainNav, index: number) => (
                    <li
                      key={index}
                      className={` ${pathname.includes(v.Link_Url) &&
                        v.Sub_items.length === 0
                        ? "active"
                        : ""
                        }`}
                    >
                      {v.Sub_items.length > 0 ? (
                        <>
                          <a onClick={() => handleTrigger(v.Main_item_name)} className={openMenus[v.Main_item_name] ? "arrow_up" : ""}>
                            <li className="groupandevents submenu-parent-link p-0">
                              {v.Main_item_name.toUpperCase()}
                              <img
                                src={
                                  IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                                  "arrow_down.svg"
                                }
                              />
                            </li>
                          </a>
                          {openMenus[v.Main_item_name] ? <ul className="sub-menu">
                            {v.Sub_items.map((sub, i: number) => (
                              <div className="py-1">
                                <Link
                                  key={i}
                                  className={`${pathname.includes(sub.Link_Url)
                                    ? "active"
                                    : ""
                                    }`}
                                    to={
                                      sub.Link_Url.includes("https")
                                        ? sub.Link_Url
                                        : `/${currentCinema?.alias}${sub.Link_Url}`
                                    }
                                    target={
                                      sub.Link_Url.includes("https")
                                        ? "_blank"
                                        : "_self"
                                    }
                                >
                                  <div onClick={onCloseMenu}>
                                    {sub.Subitem_Name.toLowerCase() ===
                                      "titan luxe" ? (
                                      <img
                                        className="titan_luxe"
                                        src={
                                          IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                                          "titanluxe_black_new.svg"
                                        }
                                      />
                                    ) : sub.Subitem_Name.toLowerCase() ===
                                      "titan xc" ? (
                                      <img
                                        className="titan_xc"
                                        src={
                                          IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                                          "titanxc_black.svg"
                                        }
                                      />
                                    ) : sub.Subitem_Name.toLowerCase() ===
                                      "imax" ? (
                                      <img
                                        className="imax"
                                        src={
                                          IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                                          "imax_black.svg"
                                        }
                                      />
                                    ) : (
                                      sub.Subitem_Name.toUpperCase()
                                    )}</div>
                                </Link>
                              </div>
                            ))}
                          </ul> : null}
                        </>
                      ) : (
                        <Link
                        to={
                          v.Link_Url.includes("https")
                            ? v.Link_Url
                            : `/${currentCinema?.alias}${v.Link_Url}`
                        }
                        target={v.Link_Url.includes("https") ? "_blank" : "_self"}
                          className={`nav-link1`}
                        >
                          <div onClick={onCloseMenu}>{v.Main_item_name.toUpperCase()}</div>
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="header-us d-none d-md-block">
        <div className="wrapper" onClick={() => setShowQuickTickets(false)}>
          <div style={{ width: "25%" }}>
            <div className={`search-field ${showSearch ? "clicked" : ""}`} role="search" aria-controls="search-input" aria-expanded={showSearch ? "true" : "false"}>
              <img
                src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "search.svg"}
                alt="search icon"
                className="pointer"
                role="button"
                aria-label="search"
                onClick={onClickSearch}
                tabIndex={0}
              />
              {showSearch?<input onKeyDown={onSearch} ref={searchRef} aria-label="search" id="search-input" name="search"/>:null}
            </div>
          </div>

          <div className="d-flex align-item-center">
            <div className="logo">
              <Link
                to={`/${currentCinema?.alias}`}
                className="d-flex align-item-center"
              >
                <img
                  style={{ width: 72 }}
                  src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + siteLogo}
                  alt="site logo image"
                />
                <div className="site-name pointer">{siteName}</div>
              </Link>
            </div>
          </div>
          <div style={{ width: "25%" }}>
            <ul className="social-media">
              {currentCinema?.facebook_url ? (
                <li>
                  <a href={currentCinema?.facebook_url} target="_blank" title="Click here to go to our Facebook page" aria-label="Click here to go to our Facebook page">
                    <img
                      onMouseOver={(e) =>
                      (e.currentTarget.src =
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        hoverIcons.facebook)
                      }
                      onMouseOut={(e) =>
                      (e.currentTarget.src =
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        "facebook_icon.svg")
                      }
                      src={
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        "facebook_icon.svg"
                      }
                      alt="facebook image"
                    />
                  </a>
                </li>
              ) : null}
              {currentCinema?.instagramUrl ? (
                <li>
                  <a href={currentCinema?.instagramUrl} target="_blank" title="Click here to go to our Instagram page" aria-label="Click here to go to our Instagram page">
                    <img
                      onMouseOver={(e) =>
                      (e.currentTarget.src =
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        hoverIcons.instagram)
                      }
                      onMouseOut={(e) =>
                      (e.currentTarget.src =
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        "instagram_icon.svg")
                      }
                      src={
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        "instagram_icon.svg"
                      }
                      alt="instagram image"
                    />
                  </a>
                </li>
              ) : null}
              {currentCinema?.twitter_url ? (
                <li>
                  <a href={currentCinema?.twitter_url} target="_blank" title="Click here to go to our Twitter page" aria-label="Click here to go to our Twitter page">
                    <img
                      onMouseOver={(e) =>
                      (e.currentTarget.src =
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        hoverIcons.twitter)
                      }
                      onMouseOut={(e) =>
                      (e.currentTarget.src =
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        "twitter-logo-new.svg")
                      }
                      src={
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        "twitter-logo-new.svg"
                      }
                      alt="twitter image"
                      style={{ padding: "12px" }}
                    />
                  </a>
                </li>
              ) : null}
              {currentCinema?.tiktok ? (
                <li>
                  <a href={currentCinema?.tiktok} target="_blank" title="Click here to go to our Tiktok page" aria-label="Click here to go to our Tiktok page">
                    <img
                      onMouseOver={(e) =>
                      (e.currentTarget.src =
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        hoverIcons.tiktok)
                      }
                      onMouseOut={(e) =>
                      (e.currentTarget.src =
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        "tiktok_icon.svg")
                      }
                      src={
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "tiktok_icon.svg"
                      }
                      alt="tiktok image"
                    />
                  </a>
                </li>
              ) : null}
              {currentCinema?.youtubeUrl && currentCinema?.youtubeUrl.trim() ? (
                <li>
                  <a href={currentCinema?.youtubeUrl} target="_blank" title="Click here to go to our Youtube page" aria-label="Click here to go to our Youtube page">
                    <img
                      className="youtube"
                      onMouseOver={(e) =>
                      (e.currentTarget.src =
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        hoverIcons.youtube)
                      }
                      onMouseOut={(e) =>
                      (e.currentTarget.src =
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        "youtube_icon.svg")
                      }
                      src={
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "youtube_icon.svg"
                      }
                      alt="youtube image"
                    />
                  </a>
                </li>
              ) : null}
              {currentCinema?.email ? (
                <li>
                  <a href={`mailto:${currentCinema?.email}`} target="_blank" title="Click here to drop us an email" aria-label="Click here to drop us an email">
                    <img
                      className="email"
                      onMouseOver={(e) =>
                      (e.currentTarget.src =
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + hoverIcons.mail)
                      }
                      onMouseOut={(e) =>
                      (e.currentTarget.src =
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "mail_icon.svg")
                      }
                      src={
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + "mail_icon.svg"
                      }
                      alt="email image"
                    />
                  </a>
                </li>
              ) : null}
            </ul>
            <div className="signin-wrapper">
              <div className="d-flex justify-content-end">
                <div className="sign-in">
                  {isLoggedIn ? (
                    <div className={`d-flex ${countryId === COUNTRY.AFC?'mx-xxl-4':'mx-xxl-2'}`}>
                      {countryId === COUNTRY.AFC ? <a role="button" className="btn-rewards" target="_blank" href={URLS.ANGELIKA_FILM_MEMBERSHIP}>rewards</a> : null}
                      <ul className={`${popoverVisible ? 'close' : ''}`}>
                        <li className="profile-icon highlightbox"
                          onClick={(e) => (countryId === COUNTRY.AFC && popoverVisible === false) ? modifyPopover(true) : onClickEdit()}>
                          <img
                            className="profile-icon"
                            src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}signin_icon.svg`}
                            alt="signin image"
                          />
                          <img
                            className="profile-icon-hover"
                            src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}${hoverIcons.signin}`}
                            alt="signin image"
                          />
                          {userDetails?.firstName}
                          {countryId === COUNTRY.AFC ? (
                            <span className="header-arrow float-right edit"><span className="chevron_right"></span></span>
                          ) :  Number(points) >= 0 && showPoints ? (
                            <span className="points-label">({points}&nbsp;Pts)</span>
                          ) : null}
                          <span className="tooltiptext" ref={tooltipRef}>
                            <h4 className="heading">Welcome back!</h4>
                            <p className="title">Please use <span className="view-profile">VIEW PROFILE</span> to access below information:</p>
                            <div className="desc">
                              <ul>
                              {countryId === COUNTRY.AFC?<li>Angelika Membership Rewards and ID Number</li>:null}
                                <li>Upcoming Movie Tickets and Purchase History</li>
                                <li>Manage Gift Cards & E-Gift Cards</li>
                                <li>Update payment information</li>
                              </ul>
                            </div>
                            <div>
                              <button className="btn-got-it" onClick={()=>closeTooltip(tooltipRef)}>Got it!</button>
                            </div>
                          </span>
                        </li>
                        {/* {countryId === COUNTRY.AFC ? (
                          <li>REWARDS ({points})</li>
                        ) : null} */}
                        {/* <li onClick={onClickEdit}>VIEW PROFILE</li> */}
                        {countryId != COUNTRY.AFC ? (
                          <li onClick={logout}>LOG OUT</li>) : null}
                      </ul>
                    </div>
                  ) : (
                    <>
                    {countryId === COUNTRY.AFC ? <a role="button" className="btn-rewards" target="_blank" href={URLS.ANGELIKA_FILM_MEMBERSHIP}>rewards</a> : null}
                    <div
                      onClick={() => (countryId === COUNTRY.AFC && popoverVisible === false) ? modifyPopover(true) : onSignIn()}
                      onMouseOver={(e) => (countryId === COUNTRY.AFC && popoverVisible === false) ? modifyPopover(true) : null}
                      className="d-flex align-items-center sign-in-logo"
                    >
                      <span
                        className={`${countryId === COUNTRY.CON ? "text-uppercase" : ""
                          } mx-2`}
                      >
                        Sign in
                      </span>
                      <img
                        className="signin-icon"
                        src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}signin_icon.svg`}
                        alt="sign-in image"
                        role="button"
                        tabIndex={1}
                      />
                      <img
                        className="signin-icon-hover"
                        src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}${hoverIcons.signin}`}
                        alt="sign-in image"
                        role="button"
                        tabIndex={1}
                      />
                    </div>
                    </>
                  )}
                </div>
              </div>
              {countryId === COUNTRY.AFC ? (
                <Popover visible={popoverVisible} logged={isLoggedIn}>
                  {isLoggedIn ? (
                    <>
                      <div className="logged-profile" onMouseLeave={() => modifyPopover(false)}>
                        <div className="header">
                          <div className="user-info">
                            <span className="user-icon">
                              <img className="mx-1"
                                src={`${COUNTRY_IMAGE_CONFIG[COUNTRY.AFC]}menu-user-icon-pink.svg`}
                                alt="signin image"
                              />
                            </span>
                            <span className="menu-user-name ">
                              {userDetails?.firstName} {Number(points) >= 0 && showPoints ? (
                                <>(<span className="points-label">{points}</span> Pts)</>
                              ) : null}
                            </span>
                          </div>
                        </div>
                        {PROFILE_MEMU.map(v=><div className="nav-item">
                          <span className="nav-icon">
                            <img className="mx-1"
                              src={`${COUNTRY_IMAGE_CONFIG[COUNTRY.AFC]}${v.img}`}
                              alt={v.altText}
                              width={v?.width}
                              height={v?.height}
                            />
                          </span>
                          <span className="nav-text" onClick={() => modifyPopover(false)}>
                            <Link to={`/${currentCinema?.alias}/${v.link}`} target="_self">{v.label}</Link>
                          </span>
                        </div>)}
                        <div className="nav-item" onClick={logout}>
                          <span className="nav-icon">
                            <img className="mx-1"
                              src={`${COUNTRY_IMAGE_CONFIG[COUNTRY.AFC]}menu-logout.svg`}
                              alt="LOG OUT image"
                            />
                          </span>
                          <span className="nav-text">LOG OUT</span>
                        </div>
                        <button className="close-button close_icon" aria-label="close pre-sign in popover"
                          onClick={() => modifyPopover(false)}></button>
                      </div>
                    </>
                  ) : (
                    <><h4 className="heading"><b>Angelika Rewards</b> are waiting.</h4>
                      <div className="desc">
                        <ul>
                          <li>
                            <img src={COUNTRY_IMAGE_CONFIG[countryId] + "checkmark.svg"} role="background"
                              aria-label="background-image-checkmark"></img>
                            <span>Earn 1 Point for Every $1 you spend</span>
                          </li>
                          <li>
                            <img src={COUNTRY_IMAGE_CONFIG[countryId] + "checkmark.svg"} role="background"
                              aria-label="background-image-checkmark"></img>
                            <span>Points = Earn $$$ Rewards</span>
                          </li>
                          <li>
                            <img src={COUNTRY_IMAGE_CONFIG[countryId] + "checkmark.svg"} role="background"
                              aria-label="background-image-checkmark"></img>
                            <span>10% off Food & Drink and more!</span>
                          </li>
                        </ul>
                      </div>
                      <button className="close-button close_icon" aria-label="close pre-sign in popover"
                        onClick={() => modifyPopover(false)}></button>
                      <div className="popover-btn-container">
                        <button className="popover-signin" aria-label="open sign in popup" onClick={onSignIn}>Sign in
                          or Join for Free
                        </button>
                      </div>
                    </>
                  )}
                </Popover>
              ) : null}
            </div>
          </div>
        </div>
        <div className="menu-wrapper">
          <div>
            <ul>
              <li>
                {currentCinema ? (
                  <div className="d-flex justify-content-center">
                    {countryId ? <img
                      className="mx-1"
                      src={COUNTRY_IMAGE_CONFIG[countryId] + "location.svg"}
                      alt="location image"
                    /> : null}
                    <LocationDropDown
                      value={{
                        value: currentCinema ? currentCinema.slug : "",
                        label: currentCinema
                          ? countryId === COUNTRY.CON
                            ? currentCinema.displayName.toUpperCase()
                            : currentCinema.name.toUpperCase()
                          : "",
                      }}
                      options={cinemaList}
                      placeholder="SELECT YOUR CITY"
                      onChange={onChangeCinema}
                      className={
                        countryId === COUNTRY.CON
                          ? "location-dropdown-con"
                          : countryId === COUNTRY.AFC
                            ? "location-dropdown-afc"
                            : "location-dropdown-us"
                      }
                      classNamePrefix="location-us"
                      brandId={brandId}
                      countryId={countryId}
                    />
                  </div>
                ) : null}
              </li>
              {menu.header.map((v: IMainNav, index: number) => (
                <li
                  key={index}
                  className={`menu ${pathname.includes(v.Link_Url) && v.Sub_items.length === 0
                    ? "active"
                    : ""
                    }`}
                >
                  {v.Sub_items.length > 0 ? (
                    <div className="dropdown">
                      <a className="dropbtn">
                        <span
                          className={
                            v.Sub_items.length > 0 &&
                              v.Sub_items.filter((v) =>
                                pathname.includes(v.Link_Url)
                              ).length > 0
                              ? "label active mx-2"
                              : "mx-2 label"
                          }
                        >
                          {v.Main_item_name.toUpperCase()}
                        </span>
                        <img
                          src={
                            COUNTRY_IMAGE_CONFIG[countryId] + "arrow_down.svg"
                          }
                          alt="country dropdown image"
                        />
                        <div className="dropdown-content">
                          {v.Sub_items.map((sub, i: number) => (
                            <Link
                              key={i}
                              className={`${pathname.includes(sub.Link_Url) ? "active" : ""
                                }`}
                              to={
                                sub.Link_Url.includes("https")
                                  ? sub.Link_Url
                                  : `/${currentCinema?.alias}${sub.Link_Url}`
                              }
                              target={
                                sub.Link_Url.includes("https")
                                  ? "_blank"
                                  : "_self"
                              }
                            >
                              <span>
                                {sub.Subitem_Name.toLowerCase() ===
                                  "titan luxe" ? (
                                  <img
                                    className="titan_luxe"
                                    src={
                                      IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                                      "titanluxe_black_new.svg"
                                    }
                                  />
                                ) : sub.Subitem_Name.toLowerCase() ===
                                  "titan xc" ? (
                                  <img
                                    className="titan_xc"
                                    src={
                                      IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                                      "titanxc_black.svg"
                                    }
                                  />
                                ) : sub.Subitem_Name.toLowerCase() ===
                                  "imax" ? (
                                  <img
                                    className="imax"
                                    src={
                                      IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                                      "imax_black.svg"
                                    }
                                  />
                                ) : (
                                  sub.Subitem_Name.toUpperCase()
                                )}
                              </span>
                            </Link>
                          ))}
                        </div>
                      </a>
                    </div>
                  ) : (
                    <Link
                      to={
                        v.Link_Url.includes("https")
                          ? v.Link_Url
                          : `/${currentCinema?.alias}${v.Link_Url}`
                      }
                      target={v.Link_Url.includes("https") ? "_blank" : "_self"}
                      className="main-menu"
                    >
                      {v.Main_item_name.toUpperCase()}
                    </Link>
                  )}
                </li>
              ))}
              <li
                className={`quick-tickets-btn ${showQuickTickets ? "active" : ""
                  } ${countryId === COUNTRY.AFC ? "quick-tickets-afc" : ""}`}
                onClick={() => onShowQuickTickets()}
                role="button"
                tabIndex={0}
              >
                Quick Tickets
              </li>
            </ul>
          </div>
        </div>
      </div>
      {showQuickTickets ? (
        <div className={filterClass ? "show-filter" : "hide-filter"}>
          <QuickTickets cinemas={cinemaList} />
          <div
            onClick={() => setShowQuickTickets(false)}
            className={`quick-filter-backDrop ${showQuickTickets ? "opened" : "closed"
              }`}
          ></div>
        </div>
      ) : null}

      {countryId !== COUNTRY.AFC?<UpdateProfile />:null}
    </>
  );
};

export default Header;
