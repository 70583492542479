import React, { useEffect, useState } from "react";
import "./popover.scss";

interface IPopover {
  visible: boolean;
  logged: boolean;
  children: React.ReactNode;
}

const Popover: React.FC<IPopover> = ({
  children,
  visible,
  logged,
  ...props
}) => {

    const [isVisible, setIsVisible] = useState(visible);
    const [isLogged, setIsLogged] = useState(visible);

    useEffect(() => {
        setIsVisible(visible);
        setIsLogged(logged);
    }, [visible, logged]);

    return (
        <div
        className={`custom-popover ${isVisible ? 'visible' : ''} ${isLogged ? 'custom-logged' : ''} `}
        aria-label="popover"
        {...props}
        >
            {children}            
        </div>
    );
};

export default Popover;