import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { CardDetail } from "../../../../../models/common";
import { toast } from "react-toastify";
import TextInput from "../../../../UI/TextInput";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../../utils/validatorSchema";
import SelectDropdown from "../../../../UI/DropDown/SelectDropDown";
import {
  saveCardApi,
  updateCreditCardApi,
  updateSavedCardApi,
} from "../../../../../services/auth";
import { BRANDS, COUNTRY, MONTH_LIST } from "../../../../../constants/app";
import Button from "../../../../UI/Button";

interface IAddCardProps {
  data?: CardDetail | null;
  isEdit: boolean;
  setTabType: React.Dispatch<React.SetStateAction<any>>;
  onReset: ()=>void;
}

const AddCard: FC<IAddCardProps> = ({ data, isEdit, setTabType, onReset }) => {
  const { countryId, userDetails, currentCinema, brandId } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    userDetails: state.authReducer.userDetails,
    currentCinema: state.applicationReducer.currentCinema,
    brandId: state.applicationReducer.brandId
  }));

  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [itemList, setItemList] = useState<
    Array<{
      value: string;
      label: string;
    }>
  >(MONTH_LIST);

  const yearList = new Array(21).fill(1).map((v: number, i: number) => {
    const year = new Date().getFullYear();
    return {
      value: `${year + i}`,
      label: year + i,
    };
  });

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: createValidationSchema(
      isEdit ? "editPaymentMethod" : "paymentMethod"
    ),
    initialValues: {
      cardNumber: isEdit ? data?.cardNumber : "",
      cardHolderName: isEdit ? data?.cardName! : "",
      cvc: "",
      month: isEdit ? data?.expiryDate.split("/").shift() : "",
      year: isEdit ? data?.expiryDate.split("/").pop() : "",
    },
    onSubmit: async (values) => {
      let payload: any = {
        countryId: countryId,
        token: data?.token,
        emailId: userDetails?.email!,
        expirymonth: values.month,
        expiryyear: values.year,
        name: values.cardHolderName,
        memberRefId: userDetails?.result?.memberRefId!,
      };

      if (isEdit) {
        let cardUpdateStatus = true;
        if (countryId !== COUNTRY.NZ && brandId !== BRANDS.US) {
          const {
            data: { status, error },
          } = await updateCreditCardApi(payload);
          cardUpdateStatus = status;
          if(error){
            toast.error(error);
            return;
          }
        }
        if (cardUpdateStatus == true) {
          const {
            data: { statusCode, result, data },
          } = (await updateSavedCardApi(payload)) as any;

          if (statusCode && statusCode === 200 && result) {
            toast.success(
              isEdit ? "Card updated successfully" : "Card added successfully."
            );
            resetForm();
            onReset();
          } else {
            toast.error(
              data ? data : `Error in ${isEdit ? "updating" : "adding"} card.`
            );
          }
        } else {
          toast.error(`Error in ${isEdit ? "updating" : "adding"} card.`);
        }
      } else {
        payload = {
          ...payload,
          number: values.cardNumber,
          cvcnumber: values.cvc,
          theatreId: currentCinema?.slug
        };
        const {
          data: { statusCode, data },
        } = (await saveCardApi(payload)) as any;
        if (statusCode) {
          toast.success("Card added successfully.");
          resetForm();
          onReset();
        } else {
          toast.error(Array.isArray(data) && data.length > 0 ? data[0] : (data?data:"Error in adding card."));
        }
      }
    },
  });

  const reset = () => {
    resetForm();
    onReset();
  };

  return (
    <div className="row my-4">
      <div className="col-md-12">
        <div className="login-form row">
          <div className="col-12 col-md-12 mt-4">
            <label className="form-label">CARD HOLDER NAME</label>
            <div className="form-group">
              <TextInput
                type="text"
                value={values.cardHolderName}
                field={"cardHolderName"}
                handleChange={handleChange}
                touched={touched.cardHolderName!}
                error={errors.cardHolderName}
              />
            </div>
          </div>
          <div className="col-12 col-md-12">
            <label className="form-label">CARD NUMBER</label>
            <div className="form-group">
              <TextInput
                type="text"
                value={values.cardNumber}
                field={"cardNumber"}
                handleChange={handleChange}
                touched={touched.cardNumber!}
                error={errors.cardNumber}
                maxLength={19}
                disabled = {isEdit}
              />
            </div>
          </div>
          <div className="col-4 col-md-4">
            <label className="form-label">EXPIRY MONTH</label>
            <div className=" form-group">
              <SelectDropdown
                placeholder="Month"
                field={"month"}
                options={itemList}
                setFieldValue={setFieldValue}
                touched={touched.month!}
                error={errors.month}
                value={values.month}
              />
            </div>
          </div>
          <div className="col-4 col-md-4">
            <label className="form-label">EXPIRY YEAR</label>
            <div className=" form-group">
            <SelectDropdown
              placeholder="Year"
              field={"year"}
              options={yearList}
              setFieldValue={setFieldValue}
              touched={touched.year!}
              error={errors.year}
              value={values.year}
            />
            </div>
          </div>
          <div className="col-4 col-md-4">
            <label className="form-label">CVC</label>
            <div className="form-group">
              <TextInput
                type="password"
                value={values.cvc}
                field={"cvc"}
                handleChange={handleChange}
                touched={touched.cvc!}
                error={errors.cvc}
                maxLength={4}
                autoComplete="new-password"
                name="cvv"
              />
            </div>
          </div>

          <div className="col-md-12 text-center error_message">
            <span>{errorMessage}</span>
          </div>
          <div className="col-md-12 popup_center_btns">
            <div className="movie_button_wrap">
              <Button classNames="m-r-10" onClick={reset} text="Cancel" type="outline" />
              <Button iconWidth={10} iconHeight={20} onClick={handleSubmit} text= "Submit" type="fill" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCard;
