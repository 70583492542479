import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { calTotal } from "../../../../../utils/giftshop";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../../utils/validatorSchema";
import { sendMailApi } from "../../../../../services/payment";
import { toast } from "react-toastify";
import { ICartData } from "../../../../../store/giftshop/type";
import { GiftType, PROMO_TYPE } from "../../../../../models/giftcard";
import moment from "moment";
import "./confirmation.scss";
import { IMAGE_URL_CONFIG, URLS } from "../../../../../constants/url";
import Barcode from "react-barcode";
import TextInput from "../../../../../components/UI/TextInput";
import { BRANDS } from "../../../../../constants/app";
import Button from "../../../../../components/UI/Button";

export interface GiftshopConfirmationProps {
  onCloseModal: () => void;
}

const GiftshopConfirmation: FC<GiftshopConfirmationProps> = ({
  onCloseModal,
}) => {
  const { countryId, cart, bookingId, orderResponse, brandId } = useSelector(
    (state: RootState) => ({
      cart: state.giftCardReducer.cart,
      bookingId: state.giftCardReducer.bookingId,
      orderResponse: state.giftCardReducer.orderResponse,
      countryId: state.applicationReducer.countryId,
      brandId: state.applicationReducer.brandId,
    })
  );

  const [cartData, setCartData] = useState<{
    deliveryFee: number;
    deliveryQty: number;
    total: number;
    shippingAddress: Array<any>;
  }>({ deliveryFee: 0, deliveryQty: 0, total: 0, shippingAddress:[] });

  const [isMailSent, setIsMailSent] = useState(false);

  useEffect(() => {
    if (cart && countryId) {
      setCartData(calTotal(cart, countryId));
    }
  }, [cart, countryId]);

  const { handleChange, handleSubmit, values, errors, touched, setFieldValue } =
    useFormik({
      validationSchema: createValidationSchema("sendReceipt"),
      enableReinitialize: true,
      initialValues: {
        email: "",
      },
      onSubmit: async (values) => {
        const payload = {
          id: orderResponse?.userSessionId!,
          countryId: countryId,
          templateName: "Giftcard order confirmation",
          email: values.email,
        };
        const { status, error }: any = await sendMailApi(payload);
        if (status) {
          setIsMailSent(true);
          toast.success("Mail sent successfully");
        } else {
          setIsMailSent(false);
          toast.error(error || "Something went wrong");
        }
      },
    });

  return (
    <div>
      <div className="modal-giftcard-thankspg mx-3">
        <div className="modal-purchase-code">
          <div className="purchase-code-left">
            <div>
              <p className="m-0">PURCHASE REFERENCE</p>
              <h5>{bookingId}</h5>
            </div>
            <div className="bar_code">
              {brandId === BRANDS.AU ? (
                <Barcode
                  width={2}
                  height={80}
                  displayValue={false}
                  value={bookingId}
                />
              ):null}
            </div>
          </div>
          <div className="purchase-code-right">
            <img src="purchaseCode" alt="" />
          </div>
        </div>
        <div className="mx-3">
          <div className="purchase-details-main">
            {cart &&
              cart.length > 0 &&
              cart.map((item: ICartData) => (
                <div className="purchase-details-sec">
                  <h3>
                    {item?.promo ? "$"+item?.promo?.amount+" Bonus" : item.description}{" "}
                    {item?.promoType === PROMO_TYPE.EXISTING
                      ? ` + ${item?.promo?.name}`
                      : ""}
                  </h3>
                  {item.giftcardType == GiftType.ecard ? (
                    <p>
                      <span>Gift Type:&nbsp;</span>E-Gift Card
                    </p>
                  ) : item.giftcardType == GiftType.physical ? (
                    <p>
                      <span>Gift Type:&nbsp;</span>Physical Gift Card
                    </p>
                  ) : null}
                  {/* item.giftcardType == GiftType.exp ? (
                    <p>
                      <span>Gift Type:</span> Experience
                    </p>
                  ) : null} */}
                  <p>
                    <span>Gift To:&nbsp;</span>{item.recipientName}
                  </p>
                  {item.giftcardType == GiftType.ecard ? (
                    <p>
                      <span>Deliver To:&nbsp;</span>{item.recipientemail}
                    </p>
                  ) : null}
                  {item.giftcardType !== GiftType.ecard ? (
                    <p>
                      <span>Deliver To:&nbsp;</span>
                      {item.address1}
                      {item.address2},{item.city},{item.state},{item.postCode}
                    </p>
                  ) : null}
                  {item.giftcardType == GiftType.ecard ? (
                    <p>
                      <span>Delivery Date:&nbsp;</span>
                      {moment(item.deliveryDate).format("MMM DD, YYYY")}
                    </p>
                  ) : null}
                  <p>
                    <span>From:&nbsp;</span>{item.senderName}
                  </p>
                  <p>
                    <span>Message:&nbsp;</span>{item.giftMessage}
                  </p>
                  <p>
                    <span>Quantity:&nbsp;</span>{item.quantity}
                  </p>
                  {item?.promoType === PROMO_TYPE.SEPARATE ? (
                    <p>Free</p>
                  ) : (
                    <p>
                      <span>Item Total:&nbsp;</span>${item.itemTotal.toFixed(2)}
                    </p>
                  )}
                </div>
              ))}
          </div>
          {cartData.deliveryFee && brandId === BRANDS.AU? (
            <div className="cart-detail-delivery">
              <h4>${cartData.deliveryFee.toFixed(2)} Delivery Fee</h4>
              <ul>
                {cart.map((v: ICartData) => {
                  return v.giftcardType !== GiftType.ecard ? (
                    <li>
                      <p>
                        Deliver To:&nbsp;
                        <span>
                          {v.address1}
                          {v.address2}
                          {v.city}, {v.state}, {v.postCode}
                        </span>
                      </p>
                    </li>
                  ) : null;
                })}
              </ul>
            </div>
          ) : brandId === BRANDS.US && cartData.shippingAddress.length > 0?
          <div className="cart-detail-delivery">
          <h4>${cartData.deliveryFee.toFixed(2)} Delivery Fee</h4>
          <ul>
          {cartData.shippingAddress.map((item: any, index: any) => {
                    return (
                      <div className="my-1">
                        <p className="m-0">
                          ${Number(item.shippingDetails.shippingFee).toFixed(2)}
                          {item.shippingDetails.name}
                        </p>
                        <li id="cart" className="m-0" key={index}>
                          <p className="m-0">
                            Deliver To:&nbsp;
                            <span>
                              {item.address1}
                              {item.address2}
                              {item.city}, {item.state}, {item.postCode}
                            </span>
                          </p>
                        </li>
                      </div>
                    );
                  })}
          </ul>
        </div>
           : null}
          <div className="delivery_fee_det">
            <label>TOTAL PURCHASE</label>
            <h3>${cartData.total.toFixed(2)}</h3>
          </div>
        </div>
        <div className="send_sec_main">
          {isMailSent ? (
            <div className="send_sec">
              <h2>
                <img
                  src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}success-Selected.png`}
                  alt=""
                />{" "}
                Receipt Sent!
              </h2>
              <p className="email-to">
                <span>Emailed To&nbsp;</span>{values.email}
              </p>
              <div className="btn-section">
                <Button classNames="m-r-10" onClick={handleSubmit} text="Resend" type="fill" />
              </div>
            </div>
          ) : (
            <div className="sent_receipt">
              <h3>Send digital confirmation to your email</h3>
              <div className="sent_receipt_maingrp">
                <div className="form-group m-r-10">
                  <label>Email To</label>
                  <div className="email-parent-close">
                    <TextInput
                      value={values.email}
                      field={"email"}
                      handleChange={handleChange}
                      touched={touched.email!}
                      error={errors.email}
                    />

                    <img src="closeIcon" alt="" />
                  </div>
                </div>
                <div className="btn-section m-0 ">
                  <Button iconWidth={15} iconHeight={15} onClick={handleSubmit} text= "Send receipt" type="fill" iconPosition = "pre" icon={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}envelope-white.png`} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row btn-section thanks_modal_btnsec">
        <div className="col-md-12 movie_footer_btn_el ml-auto">
          <div className="movie_button_wrap d-flex justify-content-end">
            <Button text="Return To Gift Shop Home" type="fill" onClick={onCloseModal} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftshopConfirmation;
