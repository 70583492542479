import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { GiftCardDetail } from "../../../../../models/common";
import { toast } from "react-toastify";
import TextInput from "../../../../UI/TextInput";
import { useFormik } from "formik";
import { createValidationSchema } from "../../../../../utils/validatorSchema";
import {
  savedGiftcardApi,
} from "../../../../../services/auth";
import Button from "../../../../UI/Button";

interface IAddProps {
  data?: GiftCardDetail | null;
  isEdit: boolean;
  setTabType: React.Dispatch<React.SetStateAction<any>>;
  onReset: () => void;
}

const AddCard: FC<IAddProps> = ({ data, isEdit, setTabType, onReset }) => {
  const { countryId, userDetails } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    userDetails: state.authReducer.userDetails,
  }));

  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: createValidationSchema(
      isEdit ? "editSaveGiftCardMethod" : "saveGiftCardMethod"
    ),
    initialValues: {
      cardNumber: isEdit ? data?.GiftcardNumber : "",
      Id: isEdit ? data?.Id : "",
    },
    onSubmit: async (values) => {
      let payload: any = {
        countryId: countryId,
        emailId: userDetails?.email!,
        NumberLastDigits: values.cardNumber ? values.cardNumber.slice(-4) : "",
        memberRefId: userDetails?.result?.memberRefId!,
        cardNumber: values.cardNumber,
        status: isEdit ? "update" : "insert", // insert/update
        Id: isEdit? values.Id: undefined
      };

      const {
        data: { statusCode, data },
      } = (await savedGiftcardApi(payload)) as any;
      if (statusCode && statusCode === true) {
        toast.success(
          isEdit
            ? "Gift Card updated successfully"
            : "Gift Card added successfully."
        );
        resetForm();
        onReset();
      } else {
        toast.error(
          data ? data : `Error in ${isEdit ? "updating" : "adding"} card.`
        );
      }
    },
  });

  const reset = () => {
    resetForm();
    onReset();
  };

  return (
    <div className="row my-4">
      <div className="col-md-12">
        <div className="login-form row">
          <div className="col-12 col-md-6 mt-4">
            <label className="form-label">GIFT CARD NUMBER*</label>
            <div className="form-group">
              <TextInput
                type="text"
                value={values.cardNumber}
                field={"cardNumber"}
                handleChange={handleChange}
                touched={touched.cardNumber!}
                error={errors.cardNumber}
              />
            </div>
          </div>

          <div className="col-md-12 text-center error_message">
            <span>{errorMessage}</span>
          </div>
          <div className="col-md-12 popup_center_btns">
            <div className="movie_button_wrap">
              <Button classNames="m-r-10" onClick={reset} text="Cancel" type="outline" />
              <Button iconWidth={10} iconHeight={20} onClick={handleSubmit} text= "Submit" type="fill" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCard;
