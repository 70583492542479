import React, { FC, useMemo } from "react";
import { ALLOWED_MEMBER_VALIDATE, AUTH_MENU, BRANDS, COUNTRY } from "../../../constants/app";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { logOutApi } from "../../../services/auth";
import { logoutAction, showLogin } from "../../../store/auth/action";
import "./authMenu.scss";
import NewLoyalty from "./NewLoyalty";
import Loyalty from "./Loyalty";
import UpcoingShow from "./UpcomingShow";
import PurchaseHistory from "./PurchaseHistory";
import MyAccount from "./MyAccount";
import { openSliderAction } from "../../../store/application/action";
import { IMAGE_URL_CONFIG } from "../../../constants/url";
import RewardsHistory from "./RewardsHistory";
import JoinMembership from "./JoinMembership";

interface IAuthMenuProps {}

const AuthMenu: FC<IAuthMenuProps> = () => {
  const { countryId, userDetails, memberDetail, brandId, currentCinema } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      userDetails: state.authReducer.userDetails,
      memberDetail: state.authReducer.memberDetail,
      brandId: state.applicationReducer.brandId,
      currentCinema: state.applicationReducer.currentCinema
    })
  );

  const dispatch = useDispatch();

  const logOut = async () => {
    const { status } = await logOutApi();
    if (status === 200) {
      dispatch(logoutAction({}));
      dispatch(openSliderAction({ open: false, type: "" }));
      dispatch(showLogin(false));
    }
  };

  const hasRestriction = useMemo(()=>{
      return !ALLOWED_MEMBER_VALIDATE.includes(currentCinema?.slug)
  },[currentCinema])

  const isMember = useMemo(()=>{
    return memberDetail && memberDetail?.LoyaltyMember && memberDetail?.LoyaltyMember?.CardNumber?true:false
  },[memberDetail])

  return (
    <section className="auth-side-nav">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-md-12">
            {AUTH_MENU.loyaltyDetails.enable.includes(countryId) ? (
              <div className="d-flex justify-content-center mt-2">
                <div className="membership-logo">
                  {memberDetail &&
                  memberDetail?.LoyaltyMember &&
                  memberDetail.LoyaltyMember?.MemberLevelName &&
                  (memberDetail.LoyaltyMember?.MemberLevelName).includes(
                    "Gold"
                  ) ? (
                    <img
                      className="w-100"
                      src={
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        "membership-gold-logo.png"
                      }
                    />
                  ) : (
                    <img
                      className="w-100"
                      src={
                        IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH +
                        "membership-club-logo.png"
                      }
                    />
                  )}
                </div>
              </div>
            ) : null}
            {AUTH_MENU.userDetails.enable.includes(countryId) ? (
              <div className="col-md-12">
                <div className="user-details">
                  <div className="user-name">
                    <span>
                      Hello,{" "}
                      {userDetails?.firstName ? userDetails.firstName : ""}
                    </span>{" "}
                    <span>
                      {userDetails?.lastName ? userDetails.lastName : ""}
                    </span>
                  </div>
                  <div className="logout-cla" onClick={logOut}>
                    Logout
                  </div>
                  {brandId === BRANDS.US ? <div className="description">
                    This is where you can keep track of your membership, view
                    your reward points and edit
                    your personal member details.
                  </div>
                    :
                    <div className="description">
                      This is where you can keep track of your membership, view
                      your reward points, enter our fabulous competitions and edit
                      your personal member details.
                    </div>}
                </div>
              </div>
            ) : null}

            {AUTH_MENU.loyalty.enable.includes(countryId) && !hasRestriction ? (
              <div>
                <div>
                  {memberDetail && memberDetail?.ErrorDescription ? (
                    <NewLoyalty />
                  ) : (
                    <Loyalty />
                  )}
                </div>
              </div>
            ) : null}

            {AUTH_MENU.rewardsHistorySection.enable.includes(countryId) && !hasRestriction? (
              <RewardsHistory />
            ) : null}

            {AUTH_MENU.upcomingShow.enable.includes(countryId) ? (
              <UpcoingShow />
            ) : null}

            {AUTH_MENU.purchaseHistorySection.enable.includes(countryId) && !hasRestriction? (
              <PurchaseHistory />
            ) : null}

            {!isMember && !hasRestriction? <JoinMembership /> : null}

            {AUTH_MENU.accountDetails.enable.includes(countryId) ? (
              <MyAccount />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuthMenu;
