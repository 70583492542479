import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  openSliderAction,
} from "../../../../store/application/action";
import { ROUTES } from "../../../../constants/path";
import { showLogin, showUpdateProfileModal } from "../../../../store/auth/action";
import { RootState } from "../../../../store";
import { useNavigate } from "react-router";

interface UpdateProfileProps { }

const UpdateProfile: FC<UpdateProfileProps> = () => {

  const { isLoggedIn, currentCinema } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      isLoggedIn: state.authReducer.isLoggedIn,
      currentCinema: state.applicationReducer.currentCinema,
    }));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentCinema) {
      if (isLoggedIn) {
        dispatch(showUpdateProfileModal(true));
      } else {
        dispatch(showLogin(true));
        dispatch(openSliderAction({ open: false, type: "updateProfileLink" }));
      }
      navigate(`/${currentCinema?.alias}/${ROUTES.NOW_PLAYING}`);
    }
  }, [currentCinema, isLoggedIn]);

  return null;
};

export default UpdateProfile;
