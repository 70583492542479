import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import Member from "../../../../../components/Auth/Member";
import SignUp from "../../../../../components/Auth/SignUp";
import "./auth.scss";
import { toggleTicketModal } from "../../../../../store/ticketBooking/action";
import { showLogin } from "../../../../../store/auth/action";
import { BRANDS } from "../../../../../constants/app";

interface IAuthProps {
  onCloseModal: () => void;
}

const MembershipAuth: FC<IAuthProps> = ({ onCloseModal }) => {
  const { countryId, openModal, isLoggedIn, brandId, modal } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      brandId: state.applicationReducer.brandId,
      openModal: state.authReducer.openModal,
      isLoggedIn: state.authReducer.isLoggedIn,
      modal: state.ticketBookingReducer.modal,
    })
  );

  const dispatch = useDispatch();
  const [tab, setTab] = useState("sign-up");

  useEffect(() => {
    setTab("sign-up");
  }, [openModal]);

  const changeTab = (type: string) => {
    if(type === "sign-in"){
      dispatch(showLogin(true));
      dispatch(toggleTicketModal(false))
    }
    setTab(type);
  };

  return (
    <div className="row">
      <div className="col-12 auth-wrapper">
        <div className="d-flex">
          <div
            onClick={() => changeTab("sign-in")}
            className={`login-tab ${tab === "sign-in" ? "active" : ""}`}
          >
            {brandId === BRANDS.US ? "LOG IN" : "Sign-in"}
          </div>
          <div
            onClick={() => changeTab("sign-up")}
            className={`login-tab ${tab === "sign-up" ? "active" : ""}`}
          >
            {brandId === BRANDS.US ? "SIGN UP" : "Sign-up"}
          </div>
        </div>
        <div>
          <div className="my-4">
            {tab === "sign-in" ? (
              <Member backHandler={onCloseModal} fromPage={'account'} />
            ) : null}
          </div>
          <div className="my-4">
            {tab === "sign-up" ? <SignUp fromPage={modal?.data?modal.data:'membership'} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipAuth;
