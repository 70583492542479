import React, { FC, useEffect, useMemo, useState } from "react";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IMovieData } from "../../../../models/cinema";
import "./allShowTmes.scss";
import { ROUTES } from "../../../../constants/path";
import moment from "moment";
import { enableShowTime, movieSessAttriImages } from "../../../../utils/helper";
import { COUNTRY, LOCATION_MAPPER } from "../../../../constants/app";
import { COUNTRY_IMAGE_CONFIG, IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";

interface IAllShowTimesProps {
  session: IMovieData;
}

const AllShowTimes: FC<IAllShowTimesProps> = ({ session }) => {
  const { countryId, quickFilters, currentCinema } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    quickFilters: state.filmsReducer.quickFilters,
    currentCinema: state.applicationReducer.currentCinema
  }));
  const navigate = useNavigate();

  const handleImageError = (event: any) => {
    event.target.src = URLS.ERROR_IMAGE_THUMBNAIL;
  };
  const [bannerImageType, setBannerImageType] = useState<string>()
  const sessionImage = useMemo(() => {
    if (session &&
      session.showdates &&
      session.showdates.length > 0) {
      for (let show of session.showdates) {
        if (show && show.showtypes && show.showtypes.length > 0) {
          for (let sh of show.showtypes) {
            if (movieSessAttriImages(sh?.type)) {
              const image = movieSessAttriImages(quickFilters && quickFilters.format ? quickFilters.format : sh?.type);
              if (image?.image) {
                if(sh && sh?.type){
                  setBannerImageType(sh?.type?.replace(/ /g,'_')?.toLowerCase())
                }
                return COUNTRY_IMAGE_CONFIG[countryId] + image.image;
              }
            }
          }
        } else {
          return '';
        }
      }
    } else {
      return '';
    }
  }, [session])

  const signatureTitle = useMemo(() => {
    if (session &&
      session.signaturePrograming) {
      return session.signaturePrograming;
    } else {
      return '';
    }
  }, [session])

  const showTimeEnable = useMemo(()=>{
    return enableShowTime(session, currentCinema?.timeZone)
  },[session, currentCinema])

  return (
    <div className={`card__wrap--inner ${countryId === COUNTRY.CON?'consolidated':countryId === COUNTRY.AFC?'angelika-film-center':''}`}>
      <div className="card">
      <Link to={ `/${LOCATION_MAPPER[countryId][session.theater].alias}/movies/details/${
        session.movieSlug
      }`}>
        <div className="img-container">
          {sessionImage ? (
            <div className={`${bannerImageType} experience_titanluxe`}>
              <img
                src={sessionImage}
                loading="lazy" 
              />
            </div>
            ) : signatureTitle ?
              <div className="experience_titanluxe">
                <span className="signature-pgm-title">{signatureTitle}</span>
              </div> : null}
          <img
            className="movie-image"
            src={session.film_image_original_size}
            alt="Movie Image"
            onError={handleImageError}
            loading="lazy" 
          />
        </div>
        </Link>
        <div className="card__item flexible">
          <h3>{session.name}</h3>
        </div>
        <div className="card__footer">
          <div className="action">
            <div className="left">
              {/* <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+"img-icon.svg"} alt="Reading Cinemas" />
            92% */}
            </div>
            <div className="right">
              {showTimeEnable &&
              session.showdates &&
              session.showdates.length > 0 ? (
                <Link
                  className="btn-border-danger-new"
                  to={`/${LOCATION_MAPPER[countryId][session.theater].alias}/movies/details/${
                    session.movieSlug
                  }`}
                >
                  BUY TICKETS
                </Link>
              ) : !showTimeEnable &&
                session.showdates &&
                session.showdates.length > 0 ? (
                <a className="btn-border-danger-new border-0 btn-border-disable">
                  SALE NOT STARTED
                </a>
              ) : (
                <a className="btn-border-danger-new border-0">SOLD OUT</a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllShowTimes;
