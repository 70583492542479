import React from "react";
import { Tooltip } from "react-tooltip";
import { IMAGE_URL_CONFIG } from "../../../constants/url";

type SeatSelectionTooltipProps = {
  imageUrl: string;
  tooltipId: string;
  buttonName:string;
  text: string;
};

const SeatSelectionTooltip: React.FC<SeatSelectionTooltipProps> = ({ imageUrl, tooltipId, buttonName, text }) => {
  const tooltipHtml = `
      <div style="text-align: center;">
        <img src="${imageUrl}" alt="Icon" style="margin-bottom: 5px; width: 24px; height: 24px;" />
        ${text}
      </div>
    `;
  return (
    <>
      <span
        className="text-uppercase seat-selection-tooltip"
        data-tooltip-id={tooltipId}
        data-tooltip-html={tooltipHtml}
      >
        {buttonName}
      </span>
      <Tooltip id={tooltipId} className="seat-selection-tooltip-data" place="top" />
    </>
  );
};

export default SeatSelectionTooltip;
